import * as O from 'optics-ts';
import {Opt} from 'ts-opt';

import {OrderAnimalDetails} from 'api/gen/OrderAnimalDetails';

import {AnimalDetailsFormSectionValues} from '../components/AnimalDetailsForm/animal-details-form-section-values';
import {AnimalsDetailsTableType} from '../components/NewBulkForm/new-bulk-order-form-values';

export const idFromBeSuggestion = -1;

export const transformFormToAnimalDetails = (
    a: Opt<AnimalDetailsFormSectionValues>, barcode: Opt<string>,
): AnimalsDetailsTableType => ({
    id: a.prop('id').orNull() !== idFromBeSuggestion ? a.prop('id').orNull() : Date.now(),
    dob: a.prop('dob').orNull(),
    name: a.prop('name').orNull(),
    barcode: barcode.orNull(),
    breed: a.prop('breed').orNull(),
    earTag: a.prop('sampleId').orNull(),
    fatherEarTag: a.prop('fathersSampleId').orNull(),
    sex: a.prop('sex').orNull(),
    fatherLinReg: a.prop('fathersRegistry').orNull(),
    twin: a.prop('twin').orFalse(),
    lineRegistry: a.prop('registry').orNull(),
    motherEarTag: a.prop('mothersSampleId').orNull(),
    isEditing: false,
    fullName: a.prop('fullName').orNull(),
});

const animalsDetailsTableTypeO = O.optic<AnimalsDetailsTableType>();
export const setAnimalIsEditing = O.set(animalsDetailsTableTypeO.prop('isEditing'))(true);

export const transformAnimalDetailsToForm = (a: AnimalsDetailsTableType): AnimalDetailsFormSectionValues => ({
    id: a.id,
    dob: a.dob,
    name: a.name,
    breed: a.breed,
    sampleId: a.earTag,
    fathersSampleId: a.fatherEarTag,
    sex: a.sex,
    fathersRegistry: a.fatherLinReg,
    twin: a.twin,
    registry: a.lineRegistry,
    mothersSampleId: a.motherEarTag,
    isEditing: a.isEditing,
    fullName: a.fullName,
});

export const transformBEAnimalDetailsToForm = (a: OrderAnimalDetails): AnimalDetailsFormSectionValues => ({
    id: idFromBeSuggestion,
    dob: a.dob,
    name: a.name,
    breed: a.breed,
    sampleId: a.sampleId,
    fathersSampleId: a.fathersSampleId,
    sex: a.sex,
    fathersRegistry: a.fathersRegistry,
    twin: a.twin,
    registry: a.registry,
    mothersSampleId: a.mothersSampleId,
    isEditing: false,
    fullName: a.fullName,
});
