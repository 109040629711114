import {animalNameMaxLength} from 'api/gen/AnimalName';
import {breedAlpha6RegexGen} from 'api/gen/BreedAlpha6';
import {earTagRegexGen} from 'api/gen/EarTag';
import {t, tCommon} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {AnimalDetailsFormSectionValues} from './animal-details-form-section-values';

const te = t('orders/AnimalDetailsForm');

export const validateAnimalDetails = (
    values: AnimalDetailsFormSectionValues,
): Errors<AnimalDetailsFormSectionValues> => {
    const validator = new PaternityValidator(values);

    validator.nonEmpty('breed', te('breed'));
    validator.pattern('breed', breedAlpha6RegexGen(), te('breed'));
    validator.nonEmpty('sampleId', tCommon('earTag'));
    validator.pattern('sampleId', earTagRegexGen(), tCommon('earTag'));
    validator.nonEmpty('sex', te('sex'));
    validator.pattern('fathersSampleId', earTagRegexGen(), te('fathersSampleId'));
    validator.registry('fathersRegistry', te('fathersRegistry'), 'strict');
    validator.registry('registry', te('registry'));
    validator.pattern('mothersSampleId', earTagRegexGen(), te('mothersSampleId'));
    validator.maxStringLength('name', animalNameMaxLength, te('name'));

    return validator.generateErrorsObject();
};
