import {ActionSettings, Table, TableColumn, Button} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo, useCallback, useEffect, useMemo} from 'react';
import {find, Opt} from 'ts-opt';

import {CodeTableItem} from 'api/gen/CodeTableItem';
import {CodeTableValidation} from 'api/gen/CodeTableValidation';
import {NewCodeTableItem} from 'api/gen/NewCodeTableItem';
import {DialsModals} from 'dials/types/dials-modals';
import {useOurTranslation} from 'translations';

import {TestResultsModal} from './test-results-modal';

interface OwnProps {
    loading: boolean;
    modalsVisibility: Array<DialsModals>;
    testResults: Opt<Array<CodeTableItem>>;
    testResultIdBeingEdited: Opt<number>;
    toggleModalVisibility(id: DialsModals): void;
    getTestResults(): void;
    startEditingTestResult(testResult: CodeTableItem): void;
    updateTestResult(id: number, testResult: NewCodeTableItem): void;
    testResultEditingCancelled(): void;
    createTestResult(testResult: NewCodeTableItem): void;
    validateTestResultType(data: CodeTableValidation): void;
    resetTestResults(): void;
}

type Props = OwnProps;

const TestResultsBase: FC<Props> = props => {
    const {
        loading,
        modalsVisibility,
        testResults,
        testResultIdBeingEdited,
        toggleModalVisibility,
        createTestResult,
        getTestResults,
        startEditingTestResult,
        testResultEditingCancelled,
        updateTestResult,
        validateTestResultType,
        resetTestResults,
    } = props;

    useEffect(() => {
        getTestResults();
    }, [getTestResults]);

    const {t} = useOurTranslation('dials/testResults');

    const toggleTestResultsModalVisibility = useCallback(() =>
        toggleModalVisibility('testResult')
    , [toggleModalVisibility]);

    const onEdit = useCallback((id: number) =>
        testResults.chain(find(x => x.id === id))
            .onSome(testResultToEdit => {
                startEditingTestResult(testResultToEdit);
                toggleTestResultsModalVisibility();
            })
    , [testResults, startEditingTestResult, toggleTestResultsModalVisibility]);

    const onEditCancel = useCallback(() => {
        testResultEditingCancelled();
        toggleTestResultsModalVisibility();
    }, [testResultEditingCancelled, toggleTestResultsModalVisibility]);

    const onUpdate = useCallback((testResult: NewCodeTableItem) => {
        testResultIdBeingEdited.onSome(x => updateTestResult(x, testResult));
    }, [testResultIdBeingEdited, updateTestResult]);

    const columns = useMemo((): Array<TableColumn<CodeTableItem>> => [
        {
            field: 'value',
            type: 'string',
            column: t('value'),
            tooltip: t('value'),
            disableFilter: true,
        },
    ], [t]);

    const actionSettings = useMemo((): ActionSettings<CodeTableItem> => ({
        onEdit,
    }), [onEdit]);

    return (
        <Fragment>
            <div className="d-flex justify-content-end mb-2">
                <Button
                    visuals="primary"
                    icon="plusOutlined"
                    onClick={toggleTestResultsModalVisibility}
                >
                    {t('createNew')}
                </Button>
            </div>
            <Table
                tableId="testResults"
                columns={columns}
                rows={testResults.orElse([])}
                actionSettings={actionSettings}
                loading={loading}
                onUnmount={resetTestResults}
            />
            <TestResultsModal
                visible={modalsVisibility.includes('testResult')}
                testResultIdBeingEdited={testResultIdBeingEdited}
                onSubmitEdit={onUpdate}
                onSubmitCreate={createTestResult}
                onCancel={testResultIdBeingEdited.isEmpty ? toggleTestResultsModalVisibility : onEditCancel}
                validateTestResultType={validateTestResultType}
            />
        </Fragment>
    );
};

export const TestResults = memo(TestResultsBase);
