import {cloneDeep} from 'lodash/fp';

import {FileImportAction, SetLoadingAction} from './action';
import {FileImportState, initialFileImportState} from './state';

const applySetLoading = (state: FileImportState, {payload}: SetLoadingAction): FileImportState => {
    const newState = cloneDeep(state);

    newState.isLoading = payload.isLoading;

    return newState;
};

export const fileImportReducer = (
    state: FileImportState = initialFileImportState,
    action: FileImportAction,
): FileImportState => {
    switch (action.type) {
        case 'fileImport/SET_LOADING':
            return applySetLoading(state, action);
        default:
            return state;
    }
};
