import React, {FC, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {opt, Opt} from 'ts-opt';

import {ParentSearchResultDetails} from 'api/gen/ParentSearchResultDetails';
import {Action} from 'app/actions';
import {State} from 'app/state';
import {Container, MainPanel, PageHeading} from 'layout';
import {useOurTranslation} from 'translations';
import {formHelpers} from 'utils/forms';

import {ParentSearchForm} from '../components/ParentSearchForm';
import {ParentSearchFormValues} from '../components/ParentSearchForm/parent-search-form-values';
import {ParentSearchResultTable} from '../components/ParentSearchResultTable';
import {parentSearchAction} from '../model';

interface StateProps {
    isParentsTypeSelected: boolean;
    parentFieldsCount: number;
    result: Opt<Array<ParentSearchResultDetails>>;
}

interface DispatchProps {
    searchParents(data: ParentSearchFormValues): void;
    resetResults(): void;
    resetParentSearchForm(): void;
}

type Props = StateProps & DispatchProps;

const ListBase: FC<Props> = props => {
    const {
        isParentsTypeSelected,
        result,
        parentFieldsCount,
        searchParents,
        resetResults,
        resetParentSearchForm,
    } = props;

    const {t} = useOurTranslation('parentSearch/List');

    useEffect(() => {
        resetResults();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const resetFormAndResults = useCallback(() => {
        resetResults();
        resetParentSearchForm();
    }, [resetParentSearchForm, resetResults]);

    return (
        <Container>
            <MainPanel>
                <PageHeading>
                    <h1>{t('title')}</h1>
                </PageHeading>

                <ParentSearchForm
                    isParentsTypeSelected={isParentsTypeSelected}
                    parentFieldsCount={parentFieldsCount}
                    onSubmit={searchParents}
                    onReset={resetFormAndResults}
                />

            </MainPanel>

            {result.map(x => (
                <div
                    key={x.length}
                    className="mt-4"
                >
                    <MainPanel>
                        <PageHeading>
                            <h1>{t('results')}</h1>
                        </PageHeading>

                        <ParentSearchResultTable
                            result={x}
                        />
                    </MainPanel>
                </div>
            )).orNull()}
        </Container>
    );
};

const mapStateToProps = (state: State): StateProps => ({
    isParentsTypeSelected: formHelpers.formValues('parentSearch')(state).prop('searchType').equals(opt('PARENTS')),
    parentFieldsCount: formHelpers.formValues('parentSearch')(state)
        .prop('parentSampleIds').map(x => x.length).orElse(0),
    result: state.parentSearch.result,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    searchParents: (data: ParentSearchFormValues): Action => dispatch(parentSearchAction.searchParent(data)),
    resetResults: (): Action => dispatch(parentSearchAction.resetResults()),
    resetParentSearchForm: (): Action => dispatch(formHelpers.reset('parentSearch')),
});

export const List = connect(mapStateToProps, mapDispatchToProps)(ListBase);
