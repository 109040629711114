import {Table, TableColumn} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {ParenthoodVerificationDifference} from 'api/gen/ParenthoodVerificationDifference';
import {useOurTranslation} from 'translations';

interface Props {
    differences: Array<ParenthoodVerificationDifference>;
}

interface PaternityCheckTableType extends ParenthoodVerificationDifference {
    id: number;
}

const PaternityCheckTableBase: FC<Props> = props => {
    const {differences} = props;

    const {t} = useOurTranslation('paternityCheck/Table');

    const columns: Array<TableColumn<PaternityCheckTableType>> = [
        {
            field: 'snpName',
            type: 'string',
            column: t('snpName'),
            tooltip: t('snpName'),
            disableFilter: true,
        },
        {
            field: 'childAlleles',
            type: 'string',
            column: t('childAlleles'),
            tooltip: t('childAlleles'),
            disableFilter: true,
        },
        {
            field: 'parentAlleles',
            type: 'string',
            column: t('parentAlleles'),
            tooltip: t('parentAlleles'),
            width: 80,
            disableFilter: true,
        },
    ];

    const rows: Array<PaternityCheckTableType> = differences.map((x, index) => ({
        childAlleles: x.childAlleles,
        id: index,
        parentAlleles: x.parentAlleles,
        snpName: x.snpName,
    }));

    return (
        <Table
            tableId="paternityCheck"
            columns={columns}
            rows={rows}
            scroll={{x: true}}
        />
    );
};

export const PaternityCheckTable = memo(PaternityCheckTableBase);
