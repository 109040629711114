/* eslint-disable max-lines */
import {Nullable, TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {Barcode} from 'api/gen/Barcode';
import {BillingBasesExport} from 'api/gen/BillingBasesExport';
import {Date} from 'api/gen/Date';
import {LastOrderUserDetails} from 'api/gen/LastOrderUserDetails';
import {Order} from 'api/gen/Order';
import {OrderAnimalDetails} from 'api/gen/OrderAnimalDetails';
import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {OrderDetails} from 'api/gen/OrderDetails';
import {OrderDetailsForGenotypingRequest} from 'api/gen/OrderDetailsForGenotypingRequest';
import {OrderDetailsForProtocol} from 'api/gen/OrderDetailsForProtocol';
import {OrderSearch} from 'api/gen/OrderSearch';
import {PageOrder} from 'api/gen/PageOrder';
import {SampleIdTrinity} from 'api/gen/SampleIdTrinity';
import {User} from 'api/gen/User';

import {AnimalDetailsFormSectionValues} from '../components/AnimalDetailsForm/animal-details-form-section-values';
import {BulkEditOrderFormValues} from '../components/BulkEditOrderForm/bulk-edit-order-form-values';
import {OrderEditFormValues} from '../components/EditForm/order-edit-form-values';
import {EditUserDetailsFormValues} from '../components/EditUserDetailsForm/edit-user-details-form-values';
import {GenerateProtocolFormValues} from '../components/GenerateProtocolForm/generate-protocol-form-values';
import {GenerateRequestFormValues} from '../components/GenerateRequestForm/generate-request-form-values';
import {AnimalsDetailsTableType, NewBulkOrderFormValues} from '../components/NewBulkForm/new-bulk-order-form-values';
import {NewOrderFormValues} from '../components/NewForm/new-order-form-values';
import {AnimalInfoRow} from '../components/NewOrderSelectedAnimalsTable';
import {OrdersTypesFormValues} from '../components/OrderTypesForm/order-types-form-values';
import {ConfirmDialogData} from '../types/confirm-dialog-data';
import {ExportOrdersParams} from '../types/export-orders-params';
import {OrderIdentifier} from '../types/lasto-order-identifier-type';
import {ListActionModeType} from '../types/list-action-mode-type';
import {OrderListActions} from '../types/order-list-actions';
import {OrderModals} from '../types/order-modals';
import {Parent} from '../types/parent';
import {ValidateAnimal} from '../types/validate-animal-type';

interface GetOrderParams {
    setSampleDeliveredDate?: boolean;
    redirectNotFound?: boolean;
}

export const orderAction = {
    setModalVisibility: (id: OrderModals, visible: boolean) => ({
        type: 'order/SET_MODAL_VISIBILITY',
        payload: {id, visible},
    }) as const,
    toggleModalVisibility: (id: OrderModals) => ({
        type: 'order/TOGGLE_MODAL_VISIBILITY',
        payload: {id},
    }) as const,
    setListActionActive: (id: OrderListActions, active: boolean) => ({
        type: 'order/SET_LIST_ACTION_ACTIVE',
        payload: {id, active},
    }) as const,
    getOrders: (tableDataRequest: Opt<TableDataRequest<Order>>) => ({
        type: 'order/GET_ORDERS',
        payload: {tableDataRequest},
    }) as const,
    getOrdersSuccess: (ordersPage: Opt<PageOrder>) => ({
        type: 'order/GET_ORDERS_SUCCESS',
        payload: {ordersPage},
    }) as const,
    deselectAll: () => ({
        type: 'order/DESELECT_ALL',
    }) as const,
    toggleSelect: (ids: Array<number>) => ({
        type: 'order/TOGGLE_SELECT',
        payload: {ids},
    }) as const,
    createOrder: (values: NewOrderFormValues) => ({
        type: 'order/CREATE_ORDER',
        payload: {values},
    }) as const,
    createOrderSuccess: () => ({
        type: 'order/CREATE_ORDER_SUCCESS',
    }) as const,
    getUserLastOrder: (cin: Nullable<string>, identifier: OrderIdentifier) => ({
        type: 'order/GET_USER_LAST_ORDER',
        payload: {cin, identifier},
    }) as const,
    getUserLastOrderSuccess: (
        data: LastOrderUserDetails,
        requestCin: Nullable<string>,
        breederIds: Array<number>,
        identifier: OrderIdentifier,
    ) => ({
        type: 'order/GET_USER_LAST_ORDER_SUCCESS',
        payload: {data, requestCin, breederIds, identifier},
    }) as const,
    setUserDetailsFromCIN: (data: Nullable<LastOrderUserDetails>) => ({
        type: 'order/SET_USER_DETAILS_FROM_CIN',
        payload: {data},
    }) as const,
    getOrder: (orderId: number, params?: GetOrderParams) => ({
        type: 'order/GET_ORDER',
        payload: {orderId, params},
    }) as const,
    getOrderSuccess: (order: OrderDetails) => ({
        type: 'order/GET_ORDER_SUCCESS',
        payload: {order},
    }) as const,
    getDataForProtocol: (orderId: number) => ({
        type: 'order/GET_DATA_FOR_PROTOCOL',
        payload: {orderId},
    }) as const,
    getDataForProtocolSuccess: (order: OrderDetailsForProtocol) => ({
        type: 'order/GET_DATA_FOR_PROTOCOL_SUCCESS',
        payload: {order},
    }) as const,
    generateProtocol: (orderId: number, data: GenerateProtocolFormValues) => ({
        type: 'order/GENERATE_PROTOCOL',
        payload: {orderId, data},
    }) as const,
    deleteProtocol: (orderId: number) => ({
        type: 'order/DELETE_PROTOCOL',
        payload: {orderId},
    }) as const,
    getDataForGenotypingRequest: (orderId: number) => ({
        type: 'order/GET_DATA_FOR_GENOTYPING_REQUEST',
        payload: {orderId},
    }) as const,
    getDataForGenotypingRequestSuccess: (data: OrderDetailsForGenotypingRequest) => ({
        type: 'order/GET_DATA_FOR_GENOTYPING_REQUEST_SUCCESS',
        payload: {data},
    }) as const,
    generateGenotypingRequest: (orderId: number, data: GenerateRequestFormValues) => ({
        type: 'order/GENERATE_GENOTYPING_REQUEST',
        payload: {orderId, data},
    }) as const,
    updateOrder: (orderId: number, order: OrderEditFormValues, nextUrl?: string) => ({
        type: 'order/UPDATE_ORDER',
        payload: {orderId, order, nextUrl},
    }) as const,
    verifyPaternity: (data: SampleIdTrinity) => ({
        type: 'order/VERIFY_PATERNITY',
        payload: {data},
    }) as const,
    verifyPaternitySuccess: (
        probabilityBoth: number,
        probabilityFather: number,
        probabilityMother: number,
    ) => ({
        type: 'order/VERIFY_PATERNITY_SUCCESS',
        payload: {probabilityBoth, probabilityFather, probabilityMother},
    }) as const,
    verifyPaternityError: (parentsVerificationError: string) => ({
        type: 'order/VERIFY_PATERNITY_ERROR',
        payload: {parentsVerificationError},
    }) as const,
    deleteOrder: (orderId: number) => ({
        type: 'order/DELETE_ORDER',
        payload: {orderId},
    }) as const,
    validateAnimal: (
        sampleId: Nullable<string>,
        registry: Nullable<string>,
        type: ValidateAnimal,
    ) => ({
        type: 'order/VALIDATE_ANIMAL',
        payload: {sampleId, registry, type},
    }) as const,
    validateChipId: (chipId: string) => ({
        type: 'order/VALIDATE_CHIP_ID',
        payload: {chipId},
    }) as const,
    refreshMother: (sampleId: string) => ({
        type: 'order/REFRESH_MOTHER',
        payload: {sampleId},
    }) as const,
    refreshMotherSuccess: (animal: OrderAnimalDetails) => ({
        type: 'order/REFRESH_MOTHER_SUCCESS',
        payload: {animal},
    }) as const,
    refreshFather: (sampleId: Nullable<string>, registry: Nullable<string>) => ({
        type: 'order/REFRESH_FATHER',
        payload: {sampleId, registry},
    }) as const,
    refreshFatherSuccess: (animal: OrderAnimalDetails) => ({
        type: 'order/REFRESH_FATHER_SUCCESS',
        payload: {animal},
    }) as const,
    isolateByIds: () => ({
        type: 'order/ISOLATE_BY_IDS',
    }) as const,
    isolateByFilter: (withoutSelected: boolean) => ({
        type: 'order/ISOLATE_BY_FILTER',
        payload: {withoutSelected},
    }) as const,
    createGenotypeExportByIds: () => ({
        type: 'order/CREATE_GENOTYPE_EXPORT_BY_IDS',
    }) as const,
    createGenotypeExportByFilter: (withoutSelected: boolean) => ({
        type: 'order/CREATE_GENOTYPE_EXPORT_BY_FILTER',
        payload: {withoutSelected},
    }) as const,
    getOrdersCustomers: (name?: string) => ({
        type: 'order/GET_ORDERS_CUSTOMERS',
        payload: {name},
    }) as const,
    getOrdersCustomersSuccess: (customers: Array<OrderCustomerDetails>) => ({
        type: 'order/GET_ORDERS_CUSTOMERS_SUCCESS',
        payload: {customers},
    }) as const,
    setUserDetailsFormValue: (user: OrderCustomerDetails) => ({
        type: 'order/SET_USER_DETAILS_FORM_VALUE',
        payload: {user},
    }) as const,
    setBulkEditOrderUserDetailsFormValue: (user: OrderCustomerDetails) => ({
        type: 'order/SET_BULK_EDIT_ORDER_USER_DETAILS_FORM_VALUE',
        payload: {user},
    }) as const,
    setEditUserDetailsFormValue: (user: OrderCustomerDetails) => ({
        type: 'order/SET_EDIT_USER_DETAILS_FORM_VALUE',
        payload: {user},
    }) as const,
    resetValidationOutcomes: () => ({
        type: 'order/RESET_VALIDATION_OUTCOMES',
    }) as const,
    setExportBillingBaseMode: (mode: Nullable<ListActionModeType>) => ({
        type: 'order/SET_EXPORT_BILLING_BASE_MODE',
        payload: {mode},
    }) as const,
    exportBillingBaseByIds: (billingDate: Date, force: boolean) => ({
        type: 'order/BILLING_BASE_EXPORT_BY_IDS',
        payload: {billingDate, force},
    }) as const,
    exportBillingBaseByFilter: (billingDate: Date, force: boolean, withoutSelected: boolean) => ({
        type: 'order/BILLING_BASE_EXPORT_BY_FILTER',
        payload: {billingDate, force, withoutSelected},
    }) as const,
    exportBillingBaseSuccess: (billingBaseExport: BillingBasesExport) => ({
        type: 'order/BILLING_BASE_EXPORT_SUCCESS',
        payload: {billingBaseExport},
    }) as const,
    billingBaseExportClose: () => ({
        type: 'order/BILLING_BASE_EXPORT_CLOSE',
    }) as const,
    setBulkEditOrderIds: () => ({ //
        type: 'order/SET_BULK_EDIT_ORDER_IDS',
    }) as const,
    resetBulkEditOrderIds: () => ({ //
        type: 'order/RESET_BULK_EDIT_ORDER_IDS',
    }) as const,
    setBulkEditOrderFilter: (filter: ExportOrdersParams, withoutSelected: boolean) => ({
        type: 'order/SET_BULK_EDIT_ORDER_FILTER',
        payload: {filter, withoutSelected},
    }) as const,
    resetBulkEditOrderFilter: () => ({
        type: 'order/RESET_BULK_EDIT_ORDER_FILTER',
    }) as const,
    updateOrdersCustomersByIds: (orderIds: Array<number>, values: BulkEditOrderFormValues) => ({
        type: 'order/UPDATE_ORDER_CUSTOMERS_BY_IDS',
        payload: {orderIds, values},
    }) as const,
    updateOrdersCustomersByFilter: (
        values: BulkEditOrderFormValues,
        withoutSelected: boolean,
    ) => ({
        type: 'order/UPDATE_ORDER_CUSTOMERS_BY_FILTER',
        payload: {values, withoutSelected},
    }) as const,
    downloadBillingBases: (fileKey: string) => ({
        type: 'order/DOWNLOAD_BILLING_BASES',
        payload: {fileKey},
    }) as const,
    deleteBillingBase: (orderId: number, orderTypeId: number) => ({
        type: 'order/DELETE_BILLING_BASE',
        payload: {orderId, orderTypeId},
    }) as const,
    closeOrdersForBillingByIds: (force: boolean, orderId?: number) => ({
        type: 'order/CLOSE_FOR_BILLING_BY_IDS',
        payload: {force, orderId},
    }) as const,
    closeOrdersForBillingByFilter: (force: boolean, withoutSelected: boolean) => ({
        type: 'order/CLOSE_FOR_BILLING_BY_FILTER',
        payload: {force, withoutSelected},
    }) as const,
    updateCustomer: (orderId: number, customer: EditUserDetailsFormValues) => ({
        type: 'order/UPDATE_CUSTOMER',
        payload: {orderId, customer},
    }) as const,
    getBreeders: () => ({
        type: 'order/GET_BREEDERS',
    }) as const,
    getBreedersSuccess: (breeders: Array<User>) => ({
        type: 'order/GET_BREEDERS_SUCCESS',
        payload: {breeders},
    }) as const,
    prefillByHolstein: (sampleTypeId: Nullable<number>, orderTypeIds: Array<number>) => ({
        type: 'order/PREFILL_BY_HOLSTEIN',
        payload: {sampleTypeId, orderTypeIds},
    }) as const,
    searchOrders: (barcode?: string, earTag?: string) => ({
        type: 'order/SEARCH_ORDERS',
        payload: {earTag, barcode},
    }) as const,
    bulkPrefillByHolstein: (sampleTypeId: Nullable<number>, orderTypeIds: Array<number>) => ({
        type: 'order/BULK_PREFILL_BY_HOLSTEIN',
        payload: {sampleTypeId, orderTypeIds},
    }) as const,
    searchOrdersSuccess: (foundOrders: Array<OrderSearch>) => ({
        type: 'order/SEARCH_ORDERS_SUCCESS',
        payload: {foundOrders},
    }) as const,
    resetOrdersSearch: () => ({
        type: 'order/RESET_ORDERS_SEARCH',
    }) as const,
    validateLaboratoryNumber: (orderId: number, laboratoryNumber: string) => ({
        type: 'order/VALIDATE_LABORATORY_NUMBER',
        payload: {orderId, laboratoryNumber},
    }) as const,
    exportOrderProtocol: (orderId: number) => ({
        type: 'order/EXPORT_ORDER_PROTOCOL',
        payload: {orderId},
    }) as const,
    animalsSearch: (sampleId: Nullable<string>, registry: Nullable<string>, barcode?: Barcode) => ({
        type: 'order/ANIMALS_SEARCH',
        payload: {sampleId, registry, barcode},
    }) as const,
    animalsSearchSuccess: (animal: Nullable<OrderAnimalDetails>) => ({
        type: 'order/ANIMALS_SEARCH_SUCCESS',
        payload: {animal},
    }) as const,
    getLaboratoryNumberBySampleId: (sampleId: Nullable<string>, parent: Parent) => ({
        type: 'order/GET_LABORATORY_NUMBER_BY_SAMPLE_ID',
        payload: {sampleId, parent},
    }) as const,
    getLaboratoryNumberBySampleIdSuccess: (parent: Parent, laboratoryNumber: Nullable<string>) => ({
        type: 'order/GET_LABORATORY_NUMBER_BY_SAMPLE_ID_SUCCESS',
        payload: {parent, laboratoryNumber},
    }) as const,
    checkParentRegistry: (registry: string) => ({
        type: 'order/CHECK_PARENT_REGISTRY',
        payload: {registry},
    }) as const,
    resetParentsAlreadyAnalyzed: () => ({
        type: 'order/RESET_PARENTS_ALREADY_ANALYZED',
    }) as const,
    downloadWithoutForceFailed: (errors: Array<string>) => ({
        type: 'order/DOWNLOAD_WITHOUT_FORCE_FAILED',
        payload: {errors},
    }) as const,
    downloadWithoutForceResolve: (forceDownloadConfirmed: boolean) => ({
        type: 'order/DOWNLOAD_WITHOUT_FORCE_RESOLVE',
        payload: {forceDownloadConfirmed},
    }) as const,
    resetDownloadWithoutForceFailedResult: () => ({
        type: 'order/RESET_DOWNLOAD_WITHOUT_FORCE_RESULT',
    }) as const,
    exportOrdersByFilter: (withoutSelected: boolean) => ({
        type: 'order/EXPORT_ORDERS_BY_FILTER',
        payload: {withoutSelected},
    }) as const,
    exportOrdersByIds: () => ({
        type: 'order/EXPORT_ORDERS_BY_IDS',
    }) as const,
    exportByAssociationByFilter: (withoutSelected: boolean) => ({
        type: 'order/EXPORT_BY_ASSOCIATION_BY_FILTER',
        payload: {withoutSelected},
    }) as const,
    exportByAssociationByIds: () => ({
        type: 'order/EXPORT_BY_ASSOCIATION_BY_IDS',
    }) as const,
    exportForGermanyByFilter: (force: boolean, withoutSelected: boolean) => ({
        type: 'order/EXPORT_FOR_GERMANY_BY_FILTER',
        payload: {force, withoutSelected},
    }) as const,
    exportForGermanyByIds: (force: boolean) => ({
        type: 'order/EXPORT_FOR_GERMANY_BY_IDS',
        payload: {force},
    }) as const,
    exportCatalogueListsByFilter: (force: boolean, withoutSelected: boolean) => ({
        type: 'order/EXPORT_CATALOGUE_LISTS_BY_FILTER',
        payload: {force, withoutSelected},
    }) as const,
    exportCatalogueListsByIds: (force: boolean) => ({
        type: 'order/EXPORT_CATALOGUE_LISTS_BY_IDS',
        payload: {force},
    }) as const,
    exportProtocolsByFilter: (force: boolean, withoutSelected: boolean) => ({
        type: 'order/EXPORT_PROTOCOLS_BY_FILTER',
        payload: {force, withoutSelected},
    }) as const,
    exportProtocolsByIds: (force: boolean) => ({
        type: 'order/EXPORT_PROTOCOLS_BY_IDS',
        payload: {force},
    }) as const,
    cancelSubsidiesByIds: () => ({
        type: 'order/CANCEL_SUBSIDIES_BY_IDS',
    }) as const,
    cancelSubsidiesByFilter: (withoutSelected: boolean) => ({
        type: 'order/CANCEL_SUBSIDIES_BY_FILTER',
        payload: {withoutSelected},
    }) as const,
    exportCdcbByIds: () => ({
        type: 'order/EXPORT_CDCB_BY_IDS',
    }) as const,
    exportCdcbByFilter: (withoutSelected: boolean) => ({
        type: 'order/EXPORT_CDCB_BY_FILTER',
        payload: {withoutSelected},
    }) as const,
    generateSampleSheetByIds: (chipId: string, force: boolean) => ({
        type: 'order/GENERATE_SAMPLE_SHEET_BY_IDS',
        payload: {chipId, force},
    }) as const,
    generateSampleSheetByFilter: (chipId: string, force: boolean, withoutSelected: boolean) => ({
        type: 'order/GENERATE_SAMPLE_SHEET_BY_FILTER',
        payload: {chipId, force, withoutSelected},
    }) as const,
    setGenerateSampleSheetMode: (mode: Nullable<ListActionModeType>) => ({
        type: 'order/SET_GENERATE_SAMPLE_SHEET_MODE',
        payload: {mode},
    }) as const,
    setNewOrderFormSampleId: (sampleId: string) => ({
        type: 'order/SET_NEW_ORDER_FORM_SAMPLE_ID',
        payload: {sampleId},
    }) as const,
    setIsNewOrderAnimalValid: (valid: boolean) => ({
        type: 'order/SET_IS_NEW_ORDER_ANIMAL_VALID',
        payload: {valid},
    }) as const,
    setNewOrderEarNumberWarning: (warning: Nullable<string>) => ({
        type: 'order/SET_NEW_ORDER_EAR_NUMBER_WARNING',
        payload: {warning},
    }) as const,
    cancelOrdersBillingByIds: (force: boolean) => ({
        type: 'order/CANCEL_BILLING_BY_IDS',
        payload: {force},
    }) as const,
    cancelOrdersBillingByFilter: (force: boolean, withoutSelected: boolean) => ({
        type: 'order/CANCEL_BILLING_BY_FILTER',
        payload: {force, withoutSelected},
    }) as const,
    setRecivedOrderDeliveredDate: (date: string) => ({
        type: 'order/SET_RECIVED_ORDER_DELIVERED_DATE',
        payload: date,
    }) as const,
    setLoading: (isLoading: boolean) => ({
        type: 'order/SET_IS_LOADING',
        payload: isLoading,
    }) as const,
    clearCurrent: () => ({
        type: 'order/CLEAR_CURRENT',
    }) as const,
    updateOrdersTypesByIds: (orderIds: Array<number>, values: OrdersTypesFormValues) => ({
        type: 'order/UPDATE_ORDERS_TYPES',
        payload: {orderIds, values},
    }) as const,
    updateOrdersTypesByFilter: (values: OrdersTypesFormValues, withoutSelected: boolean) => ({
        type: 'order/UPDATE_ORDERS_TYPES_BY_FILTER',
        payload: {values, withoutSelected},
    }) as const,
    deleteOrdersByIds: () => ({
        type: 'order/DELETE_ORDERS_BY_IDS',
    }) as const,
    deleteOrdersByFilter: (withoutSelected: boolean) => ({
        type: 'order/DELETE_ORDERS_BY_FILTER',
        payload: {withoutSelected},
    }) as const,
    createBulkOrder: (values: NewBulkOrderFormValues) => ({
        type: 'order/CREATE_BULK_ORDER',
        payload: {values},
    }) as const,
    setUserDetailsBulkFormValue: (user: OrderCustomerDetails) => ({
        type: 'order/SET_USER_DETAILS_BULK_FORM_VALUE',
        payload: {user},
    }) as const,
    setUserDetailsBulkFromCIN: (data: Nullable<LastOrderUserDetails>) => ({
        type: 'order/SET_USER_DETAILS_BULK_FROM_CIN',
        payload: {data},
    }) as const,
    addAnimalToBulkOrderAnimalsDetails: (animalDetails: AnimalsDetailsTableType) => ({
        type: 'order/ADD_ANIMAL_TO_BULK_ORDER_ANIMAL_DETAILS',
        payload: {animalDetails},
    }) as const,
    addAnimalToBulkOrder: () => ({
        type: 'order/ADD_ANIMAL_TO_BULK_ORDER',
    }) as const,
    addAnimalToBulkOrderWithValidation: () => ({
        type: 'order/ADD_ANIMAL_TO_BULK_ORDER_WITH_VALIDATION',
    }) as const,
    tryAddAnimalToBulkOrderAnimalsDetails: (animalDetails: AnimalsDetailsTableType) => ({
        type: 'order/TRY_ADD_ANIMAL_TO_BULK_ORDER_ANIMAL_DETAILS',
        payload: {animalDetails},
    }) as const,
    removeAnimalToBulkOrderAnimalsDetails: (animalDetails: AnimalInfoRow) => ({
        type: 'order/REMOVE_ANIMAL_TO_BULK_ORDER_ANIMAL_DETAILS',
        payload: {animalDetails},
    }) as const,
    clearBulkOrderModalData: () => ({
        type: 'order/CLEAR_BULK_ORDER_MODAL_DATA',
    }) as const,
    editAnimalDetails: (animalDetails: AnimalDetailsFormSectionValues) => ({
        type: 'order/EDIT_ANIMAL_DETAILS',
        payload: {animalDetails},
    }) as const,
    activateEditAnimalDetailsForm: (animalDetails: AnimalInfoRow) => ({
        type: 'order/ACTIVATE_EDIT_ANIMAL_DETAILS',
        payload: {animalDetails},
    }) as const,
    resetIsEditing: () => ({
        type: 'order/RESET_IS_EDITING',
    }) as const,
    isValidating: (isValidating: boolean) => ({
        type: 'order/SET_VALIDATING',
        payload: {isValidating},
    }) as const,
    updateCustomersEmailByIds: (selectedOrders: Array<number>, email: string) => ({
        type: 'order/UPDATE_CUSTOMERS_EMAIL_BY_IDS',
        payload: {selectedOrders, email},
    }) as const,
    updateCustomersEmailByFilter: (selectedOrders: Array<number>, email: string, withoutSelected: boolean) => ({
        type: 'order/UPDATE_CUSTOMERS_EMAIL_BY_FILTER',
        payload: {selectedOrders, email, withoutSelected},
    }) as const,
    showConfirmDialog: (data: ConfirmDialogData) => ({
        type: 'order/SHOW_CONFIRM_DIALOG',
        payload: {data},
    }) as const,
    hideConfirmDialog: () => ({
        type: 'order/HIDE_CONFIRM_DIALOG',
    }) as const,
};

export type SetModalVisibilityAction = ReturnType<typeof orderAction.setModalVisibility>;
export type IsValidatingAction = ReturnType<typeof orderAction.isValidating>;
export type ResetIsEditingAction = ReturnType<typeof orderAction.resetIsEditing>;
export type TryAddAnimalToBulkOrderAnimalsDetailsAction =
    ReturnType<typeof orderAction.tryAddAnimalToBulkOrderAnimalsDetails>;
export type EditAnimalDetailsAction = ReturnType<typeof orderAction.editAnimalDetails>;
export type ActivateEditAnimalDetailsFormAction = ReturnType<typeof orderAction.activateEditAnimalDetailsForm>;
export type ClearBulkOrderModalDataAction = ReturnType<typeof orderAction.clearBulkOrderModalData>;
export type RemoveAnimalToBulkOrderAnimalsDetailsAction = ReturnType<
    typeof orderAction.removeAnimalToBulkOrderAnimalsDetails
    >;
export type ToggleModalVisibilityAction = ReturnType<typeof orderAction.toggleModalVisibility>;
export type BulkPrefillByHolsteinAction = ReturnType<typeof orderAction.bulkPrefillByHolstein>;
export type SetListActionActiveAction = ReturnType<typeof orderAction.setListActionActive>;
export type GetOrdersAction = ReturnType<typeof orderAction.getOrders>;
export type GetOrdersSuccessAction = ReturnType<typeof orderAction.getOrdersSuccess>;
export type DeselectAllAction = ReturnType<typeof orderAction.deselectAll>;
export type ToggleSelectAction = ReturnType<typeof orderAction.toggleSelect>;
export type CreateOrderAction = ReturnType<typeof orderAction.createOrder>;
export type CreateOrderSuccessAction = ReturnType<typeof orderAction.createOrderSuccess>;
export type GetUserLastOrderAction = ReturnType<typeof orderAction.getUserLastOrder>;
export type GetUserLastOrderSuccessAction = ReturnType<typeof orderAction.getUserLastOrderSuccess>;
export type SetUserDetailsFromCINAction = ReturnType<typeof orderAction.setUserDetailsFromCIN>;
export type GetOrderAction = ReturnType<typeof orderAction.getOrder>;
export type GetOrderSuccessAction = ReturnType<typeof orderAction.getOrderSuccess>;
export type GetDataForProtocolAction = ReturnType<typeof orderAction.getDataForProtocol>;
export type GetDataForProtocolSuccessAction = ReturnType<typeof orderAction.getDataForProtocolSuccess>;
export type GenerateProtocolAction = ReturnType<typeof orderAction.generateProtocol>;
export type DeleteProtocolAction = ReturnType<typeof orderAction.deleteProtocol>;
export type GetDataForGenotypingRequestAction = ReturnType<typeof orderAction.getDataForGenotypingRequest>;
export type GetDataForGenotypingRequestSuccessAction =
    ReturnType<typeof orderAction.getDataForGenotypingRequestSuccess>;
export type GenerateGenotypingRequestAction = ReturnType<typeof orderAction.generateGenotypingRequest>;
export type UpdateOrderAction = ReturnType<typeof orderAction.updateOrder>;
export type VerifyPaternityAction = ReturnType<typeof orderAction.verifyPaternity>;
export type VerifyPaternitySuccessAction = ReturnType<typeof orderAction.verifyPaternitySuccess>;
export type VerifyPaternityErrorAction = ReturnType<typeof orderAction.verifyPaternityError>;
export type DeleteOrderAction = ReturnType<typeof orderAction.deleteOrder>;
export type ValidateAnimalAction = ReturnType<typeof orderAction.validateAnimal>;
export type ValidateChipIdAction = ReturnType<typeof orderAction.validateChipId>;
export type RefreshMotherAction = ReturnType<typeof orderAction.refreshMother>;
export type RefreshMotherSuccessAction = ReturnType<typeof orderAction.refreshMotherSuccess>;
export type RefreshFatherAction = ReturnType<typeof orderAction.refreshFather>;
export type RefreshFatherSuccessAction = ReturnType<typeof orderAction.refreshFatherSuccess>;
export type IsolateByIdsAction = ReturnType<typeof orderAction.isolateByIds>;
export type IsolateByFilterAction = ReturnType<typeof orderAction.isolateByFilter>;
export type CreateGenotypeExportByIdsAction = ReturnType<typeof orderAction.createGenotypeExportByIds>;
export type CreateGenotypeExportByFilterAction = ReturnType<typeof orderAction.createGenotypeExportByFilter>;
export type GetOrdersCustomersAction = ReturnType<typeof orderAction.getOrdersCustomers>;
export type GetOrdersCustomersSuccessAction = ReturnType<typeof orderAction.getOrdersCustomersSuccess>;
export type SetUserDetailsFormValueAction = ReturnType<typeof orderAction.setUserDetailsFormValue>;
export type SetBulkEditOrderUserDetailsFormValueAction =
    ReturnType<typeof orderAction.setBulkEditOrderUserDetailsFormValue>;
export type SetEditUserDetailsFormValueAction = ReturnType<typeof orderAction.setEditUserDetailsFormValue>;
export type ResetValidationOutcomesAction = ReturnType<typeof orderAction.resetValidationOutcomes>;
export type SetExportBillingBaseModeAction = ReturnType<typeof orderAction.setExportBillingBaseMode>;
export type ExportBillingBaseByIdsAction = ReturnType<typeof orderAction.exportBillingBaseByIds>;
export type ExportBillingBaseByFilterAction = ReturnType<typeof orderAction.exportBillingBaseByFilter>;
export type ExportBillingBaseSuccessAction = ReturnType<typeof orderAction.exportBillingBaseSuccess>;
export type BillingBaseExportCloseAction = ReturnType<typeof orderAction.billingBaseExportClose>;
export type SetBulkEditOrderIdsAction = ReturnType<typeof orderAction.setBulkEditOrderIds>;
export type ResetBulkEditOrderIdsAction = ReturnType<typeof orderAction.resetBulkEditOrderIds>;
export type SetBulkEditOrderFilterAction = ReturnType<typeof orderAction.setBulkEditOrderFilter>;
export type ResetBulkEditOrderFilterAction = ReturnType<typeof orderAction.resetBulkEditOrderFilter>;
export type UpdateOrdersCustomersByIdsAction = ReturnType<typeof orderAction.updateOrdersCustomersByIds>;
export type UpdateOrdersCustomersByFilterAction =
    ReturnType<typeof orderAction.updateOrdersCustomersByFilter>;
export type DownloadBillingBasesAction = ReturnType<typeof orderAction.downloadBillingBases>;
export type DeleteBillingBaseAction = ReturnType<typeof orderAction.deleteBillingBase>;
export type CloseOrdersForBillingByIdsAction = ReturnType<typeof orderAction.closeOrdersForBillingByIds>;
export type CloseOrdersForBillingByFilterAction = ReturnType<typeof orderAction.closeOrdersForBillingByFilter>;
export type UpdateCustomerAction = ReturnType<typeof orderAction.updateCustomer>;
export type GetBreedersAction = ReturnType<typeof orderAction.getBreeders>;
export type GetBreedersSuccessAction = ReturnType<typeof orderAction.getBreedersSuccess>;
export type PrefillByHolsteinAction = ReturnType<typeof orderAction.prefillByHolstein>;
export type SearchOrdersAction = ReturnType<typeof orderAction.searchOrders>;
export type SearchOrdersSuccessAction = ReturnType<typeof orderAction.searchOrdersSuccess>;
export type ResetOrdersSearchAction = ReturnType<typeof orderAction.resetOrdersSearch>;
export type ValidateLaboratoryNumberAction = ReturnType<typeof orderAction.validateLaboratoryNumber>;
export type ExportOrderProtocolAction = ReturnType<typeof orderAction.exportOrderProtocol>;
export type AnimalsSearchAction = ReturnType<typeof orderAction.animalsSearch>;
export type AnimalsSearchSuccessAction = ReturnType<typeof orderAction.animalsSearchSuccess>;
export type DownloadWithoutForceFailedAction = ReturnType<typeof orderAction.downloadWithoutForceFailed>;
export type DownloadWithoutForceResolveAction = ReturnType<typeof orderAction.downloadWithoutForceResolve>;
export type ResetDownloadWithoutForceFailedResultAction =
    ReturnType<typeof orderAction.resetDownloadWithoutForceFailedResult>;
export type ExportOrdersByFilterAction = ReturnType<typeof orderAction.exportOrdersByFilter>;
export type ExportOrdersByIdsAction = ReturnType<typeof orderAction.exportOrdersByIds>;
export type ExportByAssociationByFilterAction = ReturnType<typeof orderAction.exportByAssociationByFilter>;
export type ExportByAssociationByIdsAction = ReturnType<typeof orderAction.exportByAssociationByIds>;
export type ExportForGermanyByFilterAction = ReturnType<typeof orderAction.exportForGermanyByFilter>;
export type ExportForGermanyByIdsAction = ReturnType<typeof orderAction.exportForGermanyByIds>;
export type ExportCatalogueListsByFilterAction = ReturnType<typeof orderAction.exportCatalogueListsByFilter>;
export type ExportCatalogueListsByIdsAction = ReturnType<typeof orderAction.exportCatalogueListsByIds>;
export type ExportProtocolsByFilterAction = ReturnType<typeof orderAction.exportProtocolsByFilter>;
export type ExportProtocolsByIdsAction = ReturnType<typeof orderAction.exportProtocolsByIds>;
export type CancelSubsidiesByIdsAction = ReturnType<typeof orderAction.cancelSubsidiesByIds>;
export type CancelSubsidiesByFilterAction = ReturnType<typeof orderAction.cancelSubsidiesByFilter>;
export type ExportCdcbByIdsAction = ReturnType<typeof orderAction.exportCdcbByIds>;
export type ExportCdcbByFilterAction = ReturnType<typeof orderAction.exportCdcbByFilter>;
export type GenerateSampleSheetByIdsAction = ReturnType<typeof orderAction.generateSampleSheetByIds>;
export type GenerateSampleSheetByFilterAction = ReturnType<typeof orderAction.generateSampleSheetByFilter>;
export type SetGenerateSampleSheetModeAction = ReturnType<typeof orderAction.setGenerateSampleSheetMode>;
export type GetLaboratoryNumberBySampleIdAction = ReturnType<typeof orderAction.getLaboratoryNumberBySampleId>;
export type GetLaboratoryNumberBySampleIdSuccessAction =
    ReturnType<typeof orderAction.getLaboratoryNumberBySampleIdSuccess>;
export type CheckParentRegistryAction = ReturnType<typeof orderAction.checkParentRegistry>;
export type ResetParentsAlreadyAnalyzedAction = ReturnType<typeof orderAction.resetParentsAlreadyAnalyzed>;
export type SetNewOrderFormSampleIdAction = ReturnType<typeof orderAction.setNewOrderFormSampleId>;
export type SetIsNewOrderAnimalValidAction = ReturnType<typeof orderAction.setIsNewOrderAnimalValid>;
export type SetNewOrderEarNumberWarningAction = ReturnType<typeof orderAction.setNewOrderEarNumberWarning>;
export type CancelOrdersBillingByIdsAction = ReturnType<typeof orderAction.cancelOrdersBillingByIds>;
export type CancelOrdersBillingByFilterAction = ReturnType<typeof orderAction.cancelOrdersBillingByFilter>;
export type SetRecivedOrderDeliveredDateAction = ReturnType<typeof orderAction.setRecivedOrderDeliveredDate>;
export type SetLoadingAction = ReturnType<typeof orderAction.setLoading>;
export type ClearCurrentAction = ReturnType<typeof orderAction.clearCurrent>;
export type UpdateOrdersTypesByIdsAction = ReturnType<typeof orderAction.updateOrdersTypesByIds>;
export type UpdateOrdersTypesByFilterAction = ReturnType<typeof orderAction.updateOrdersTypesByFilter>;
export type CreateBulkOrderAction = ReturnType<typeof orderAction.createBulkOrder>;
export type SetUserDetailsBulkFormValueAction = ReturnType<typeof orderAction.setUserDetailsBulkFormValue>;
export type SetUserDetailsBulkFromCINAction = ReturnType<typeof orderAction.setUserDetailsBulkFromCIN>;
export type AddAnimalToBulkOrderAnimalsDetailsAction =
    ReturnType<typeof orderAction.addAnimalToBulkOrderAnimalsDetails>;
export type DeleteOrdersByIdsAction = ReturnType<typeof orderAction.deleteOrdersByIds>;
export type DeleteOrdersByFilterAction = ReturnType<typeof orderAction.deleteOrdersByFilter>;
export type UpdateCustomersEmailByIdsAction = ReturnType<typeof orderAction.updateCustomersEmailByIds>;
export type UpdateCustomersEmailByFilterAction = ReturnType<typeof orderAction.updateCustomersEmailByFilter>;
export type ShowConfirmDialogAction = ReturnType<typeof orderAction.showConfirmDialog>;
export type HideConfirmDialogAction = ReturnType<typeof orderAction.hideConfirmDialog>;

export type OrderAction =
    | SetModalVisibilityAction
    | TryAddAnimalToBulkOrderAnimalsDetailsAction
    | ResetIsEditingAction
    | ClearBulkOrderModalDataAction
    | IsValidatingAction
    | ActivateEditAnimalDetailsFormAction
    | RemoveAnimalToBulkOrderAnimalsDetailsAction
    | ToggleModalVisibilityAction
    | SetListActionActiveAction
    | GetOrdersAction
    | GetOrdersSuccessAction
    | DeselectAllAction
    | ToggleSelectAction
    | CreateOrderAction
    | CreateOrderSuccessAction
    | GetUserLastOrderAction
    | GetUserLastOrderSuccessAction
    | SetUserDetailsFromCINAction
    | GetOrderAction
    | GetOrderSuccessAction
    | GetDataForProtocolAction
    | GetDataForProtocolSuccessAction
    | GenerateProtocolAction
    | DeleteProtocolAction
    | GetDataForGenotypingRequestAction
    | GetDataForGenotypingRequestSuccessAction
    | GenerateGenotypingRequestAction
    | UpdateOrderAction
    | VerifyPaternityAction
    | VerifyPaternitySuccessAction
    | VerifyPaternityErrorAction
    | DeleteOrderAction
    | ValidateAnimalAction
    | ValidateChipIdAction
    | RefreshMotherAction
    | RefreshMotherSuccessAction
    | RefreshFatherAction
    | RefreshFatherSuccessAction
    | IsolateByIdsAction
    | IsolateByFilterAction
    | CreateGenotypeExportByIdsAction
    | CreateGenotypeExportByFilterAction
    | GetOrdersCustomersAction
    | GetOrdersCustomersSuccessAction
    | SetUserDetailsFormValueAction
    | SetBulkEditOrderUserDetailsFormValueAction
    | SetEditUserDetailsFormValueAction
    | ResetValidationOutcomesAction
    | SetExportBillingBaseModeAction
    | ExportBillingBaseByIdsAction
    | ExportBillingBaseByFilterAction
    | ExportBillingBaseSuccessAction
    | BillingBaseExportCloseAction
    | SetBulkEditOrderIdsAction
    | ResetBulkEditOrderIdsAction
    | SetBulkEditOrderFilterAction
    | ResetBulkEditOrderFilterAction
    | UpdateOrdersCustomersByIdsAction
    | UpdateOrdersCustomersByFilterAction
    | DownloadBillingBasesAction
    | DeleteBillingBaseAction
    | CloseOrdersForBillingByIdsAction
    | CloseOrdersForBillingByFilterAction
    | UpdateCustomerAction
    | GetBreedersAction
    | GetBreedersSuccessAction
    | PrefillByHolsteinAction
    | SearchOrdersAction
    | SearchOrdersSuccessAction
    | ResetOrdersSearchAction
    | ValidateLaboratoryNumberAction
    | ExportOrderProtocolAction
    | AnimalsSearchAction
    | AnimalsSearchSuccessAction
    | DownloadWithoutForceFailedAction
    | DownloadWithoutForceResolveAction
    | ResetDownloadWithoutForceFailedResultAction
    | ExportOrdersByFilterAction
    | ExportOrdersByIdsAction
    | ExportByAssociationByFilterAction
    | ExportByAssociationByIdsAction
    | ExportForGermanyByFilterAction
    | ExportForGermanyByIdsAction
    | ExportCatalogueListsByFilterAction
    | ExportCatalogueListsByIdsAction
    | ExportProtocolsByFilterAction
    | ExportProtocolsByIdsAction
    | CancelSubsidiesByIdsAction
    | CancelSubsidiesByFilterAction
    | ExportCdcbByIdsAction
    | ExportCdcbByFilterAction
    | GenerateSampleSheetByIdsAction
    | GenerateSampleSheetByFilterAction
    | SetGenerateSampleSheetModeAction
    | GetLaboratoryNumberBySampleIdAction
    | GetLaboratoryNumberBySampleIdSuccessAction
    | CheckParentRegistryAction
    | ResetParentsAlreadyAnalyzedAction
    | SetNewOrderFormSampleIdAction
    | SetIsNewOrderAnimalValidAction
    | SetNewOrderEarNumberWarningAction
    | CancelOrdersBillingByIdsAction
    | SetRecivedOrderDeliveredDateAction
    | SetLoadingAction
    | ClearCurrentAction
    | UpdateOrdersTypesByIdsAction
    | UpdateOrdersTypesByFilterAction
    | CreateBulkOrderAction
    | SetUserDetailsBulkFormValueAction
    | SetUserDetailsBulkFromCINAction
    | AddAnimalToBulkOrderAnimalsDetailsAction
    | BulkPrefillByHolsteinAction
    | EditAnimalDetailsAction
    | DeleteOrdersByIdsAction
    | DeleteOrdersByFilterAction
    | UpdateCustomersEmailByIdsAction
    | UpdateCustomersEmailByFilterAction
    | ShowConfirmDialogAction
    | HideConfirmDialogAction
    ;
