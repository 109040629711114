import {TableDataParams} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {OriginInconsistenciesTableRow} from 'animals/components/OriginInconsistenciesTable/table-row';
import {PageAnimal} from 'api/gen/PageAnimal';
import {PageOriginInconsistency} from 'api/gen/PageOriginInconsistency';
import {State} from 'app/state';

import {AnimalsLoadingId} from '../types/animals-loading-id';

export const simpleAnimalsSelector = {
    pageTableData: ({animals}: State): Opt<PageOriginInconsistency> => animals.originInconsistenciesPage,
    animalsPage: ({animals}: State): Opt<PageAnimal> => animals.animalsPage,
    selectedOriginInconsistencyIds: ({animals}: State): Array<number> => animals.selectedOriginInconsistencyIds,
    originInconsistenciesParams: ({tables}: State): TableDataParams<OriginInconsistenciesTableRow> | undefined =>
        tables.tableStates.originInconsistencies?.tableDataParams,
    loading: ({animals}: State): Array<AnimalsLoadingId> => animals.loading,
};
