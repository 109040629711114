import {ActionSettings, ToolbarButton, TableDataRequest} from '@fl/cmsch-fe-library';
import React, {FC, memo, useMemo} from 'react';
import {Opt} from 'ts-opt';

import {GenotypeExport} from 'api/gen/GenotypeExport';
import {simpleGenotypeExportsSelector} from 'genotypeExports/model/selector';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';
import {ConnectedTable} from 'utils/tables';

import {createColumns} from './columns';

interface Props {
    proccessButtonEnabled: boolean;
    getGenotypeExports(tableDataRequest: Opt<TableDataRequest<GenotypeExport>>): void;
    exportGenotypeExport(id: number): void;
    processGenotypeExports(): void;
}

const isExportDisabled = (_id: number, row: GenotypeExport): boolean => !row.fileKey;

const GenotypeExportsTableBase: FC<Props> = props => {
    const {
        proccessButtonEnabled,
        getGenotypeExports,
        exportGenotypeExport,
        processGenotypeExports,
    } = props;

    const {t} = useOurTranslation('genotypeExports/GenotypesTable');
    const {isRoleLaborer} = useUser();

    const actionSettings: ActionSettings<GenotypeExport> = useMemo(() => ({
        extraActions: [
            {
                id: 'exportGenotype',
                icon: 'downloadOutlined',
                callback: exportGenotypeExport,
                disabled: isExportDisabled,
                title: t('export'),
                disabledTitle: t('exportDisabled'),
                testId: 'exportGenotype',
            },
        ],
    }), [exportGenotypeExport, t]);

    const headerButtons: Array<ToolbarButton> = useMemo(() => [
        {
            disabled: false,
            icon: 'settingFilled',
            onClick: processGenotypeExports,
            title: t('processButton'),
            visuals: 'primary',
            hidden: !proccessButtonEnabled,
            testId: 'processGenotypeExports',
        },
    ], [proccessButtonEnabled, processGenotypeExports, t]);

    return (
        <ConnectedTable
            tableId="genotypeExports"
            columns={createColumns(t, isRoleLaborer)}
            rowDataPageSelector={simpleGenotypeExportsSelector.pageGenotypeExports}
            onFetchTableData={getGenotypeExports}
            actionSettings={actionSettings}
            headerButtons={headerButtons}
            height={4000}
            rowActionsOnRight
        />
    );
};

export const GenotypeExportsTable = memo(GenotypeExportsTableBase);
