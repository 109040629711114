import {barcodeMaxLength, barcodeRegexGen} from 'api/gen/Barcode';
import {breedMaxLength} from 'api/gen/Breed';
import {earTagRegexGen} from 'api/gen/EarTag';
import {lineRegistryMaxLength} from 'api/gen/LineRegistry';
import {t} from 'translations';
import {PaternityValidator} from 'utils/paternity-validator';
import {Errors} from 'utils/validator';

import {GenerateRequestFormValues} from './generate-request-form-values';

const te = t('orders/GenerateRequestForm');

export const validate = (values: GenerateRequestFormValues): Errors<GenerateRequestFormValues> => {
    const validator = new PaternityValidator(values);

    validator.pattern('barcode', barcodeRegexGen(), te('barcode'));
    validator.maxStringLength('barcode', barcodeMaxLength, te('barcode'));
    validator.maxStringLength('breed', breedMaxLength, te('breed'));
    validator.maxStringLength('fathersBreed', breedMaxLength, te('fathersBreed'));
    validator.registry('fathersRegistry', te('fathersRegistry'), 'strict');
    validator.maxStringLength('fathersRegistry', lineRegistryMaxLength, te('fathersRegistry'));
    validator.maxStringLength('mothersFatherBreed', breedMaxLength, te('mothersFatherBreed'));
    validator.registry('mothersFatherRegistry', te('mothersFatherRegistry'));
    validator.maxStringLength('mothersFatherRegistry', lineRegistryMaxLength, te('mothersFatherRegistry'));
    validator.pattern('mothersSampleId', earTagRegexGen(), te('mothersSampleId'));

    return validator.generateErrorsObject();
};
