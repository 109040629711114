import {isEmpty} from 'lodash/fp';
import {Opt, opt} from 'ts-opt';

import {UserDetails} from 'api/gen/UserDetails';
import {MenuItem} from 'layout/types/menu-item';
import {t as tt, TFunction} from 'translations';
import {association, breeder, breedingUnion, isValidRoleName, laborer} from 'user/types/role-names';

// eslint-disable-next-line max-lines-per-function
export const getMenuItems = (
    user: Opt<UserDetails>,
    isMeatAssociation: boolean,
    isHolsteinAssociation: boolean,
    t: TFunction<'layout/mainMenu'>,
): Array<MenuItem> => [
    // Laborer
    {
        label: t('orders'),
        path: '/orders',
        permissions: [laborer, breeder],
        icon: 'fileDoneOutlined',
        subItems: [
            {
                label: t('newOrder'),
                path: '/orders/new',
                permissions: [laborer, breeder],
                icon: 'plusOutlined',
            },
            {
                label: t('newBulkOrder'),
                path: '/orders/new-bulk',
                permissions: [laborer, breeder],
                icon: 'plusOutlined',
            },
            {
                label: t('orders'),
                path: '/orders',
                permissions: [laborer],
                icon: 'fileDoneOutlined',
            },
            {
                label: t('ordersReceive'),
                path: '/orders/receive',
                permissions: [laborer],
                icon: 'mailOutlined',
            },
        ],
    },
    {
        label: t('paternityCheck'),
        path: '/paternity-check',
        permissions: [laborer],
        icon: 'apartmentOutlined',
    },
    {
        label: t('parentSearch'),
        path: '/parent-search',
        permissions: [laborer],
        icon: 'animalSearchIcon',
    },
    {
        label: t('importItem'),
        path: '/import',
        permissions: [laborer],
        icon: 'downloadOutlined',
        subItems: [
            {
                label: t('fileImport'),
                path: '/import',
                permissions: [laborer],
                icon: 'downloadOutlined',
            },
            {
                label: t('hungarianImport'),
                path: '/import/hungarian',
                permissions: [laborer],
                icon: 'downloadOutlined',
            },
            {
                label: t('czechImport'),
                path: '/import/czech',
                permissions: [laborer],
                icon: 'downloadOutlined',
            },
            {
                label: t('plemdataImport'),
                path: '/import/plemdata',
                permissions: [laborer],
                icon: 'downloadOutlined',
            },
            {
                label: t('ordersUpdatesImport'),
                path: '/import/orders-updates',
                permissions: [laborer],
                icon: 'downloadOutlined',
            },
        ],
    },
    {
        label: t('animals'),
        path: '/animals',
        permissions: [laborer],
        icon: 'cowIcon',
        subItems: [
            {
                label: t('animalManagement'),
                path: '/animals',
                permissions: [laborer],
                icon: 'animalListIcon',
            },
            {
                label: t('inconsistentOrigins'),
                path: '/animals/origin-inconsistencies',
                permissions: [laborer],
                icon: 'bullLineIcon',
            },
        ],
    },
    {
        label: t('administration'),
        path: '/administration/snp-management',
        permissions: [laborer],
        icon: 'settingFilled',
        subItems: [
            {
                label: t('snpManagement'),
                path: '/administration/snp-management',
                permissions: [laborer],
                icon: 'toolFilled',
            },
            {
                label: t('dials'),
                path: '/administration/dials',
                permissions: [laborer],
                icon: 'orderedListOutlined',
            },
            {
                label: t('genotypeExports'),
                path: '/administration/genotype-exports',
                permissions: [laborer],
                icon: 'exportOutlined',
            },
            {
                label: t('other'),
                path: '/administration/other',
                permissions: [laborer],
                icon: 'toolFilled',
            },
        ],
    },
    // Other
    {
        label: t('ordersList'),
        path: '/orders-list',
        permissions: [association, breedingUnion, breeder],
        icon: 'fileDoneOutlined',
    },
    {
        label: t('animalManagement'),
        path: '/animals-list',
        permissions: [breedingUnion],
        icon: 'cowIcon',
    },
    {
        label: t('genotypeExports'),
        path: '/genotype-exports',
        permissions: [association],
        icon: 'exportOutlined',
        hidden: !isMeatAssociation && !isHolsteinAssociation,
    },
    {
        label: t('inconsistentOrigins'),
        path: '/origin-inconsistencies',
        permissions: [association],
        icon: 'bullLineIcon',
        hidden: user.map(x => !x.hasFleckviehAnimals && !x.hasHolsteinAnimals).orFalse(),
    },
];

interface PathRestriction {
    onlyForLoggedIn: boolean;
    userLacksPermissions: boolean;
}

export const getPathRestriction = (
    user: Opt<UserDetails>,
    path: string,
    isMeatAssociation: boolean,
    isHolsteinAssociation: boolean,
): PathRestriction => {
    const menuItems: Array<MenuItem> = [];

    const pushToMenuItems = (item: MenuItem): void => {
        menuItems.push(item);
        item.subItems?.forEach(pushToMenuItems);
    };

    getMenuItems(user, isMeatAssociation, isHolsteinAssociation, tt('layout/mainMenu')).forEach(x => {
        pushToMenuItems(x);
    });

    const menuItem = menuItems.find(x => x.path.startsWith(path));

    if (path === '' || path === '/') {
        return {
            onlyForLoggedIn: false,
            userLacksPermissions: false,
        };
    }

    return opt(menuItem).map<PathRestriction>(x => ({
        onlyForLoggedIn: !isEmpty(x.permissions.length),
        userLacksPermissions: user
            .forAll(y => !isValidRoleName(y.role.name) || !x.permissions.includes(y.role.name) || Boolean(x.hidden)),
    })).orElse({
        onlyForLoggedIn: false,
        userLacksPermissions: false,
    });
};
