import {AnyTableColumn} from '@fl/cmsch-fe-library';

import {TFunction} from 'translations';

import {getCorrectOriginOptions} from '../../utils/correct-origin-options';
import {getParenthoodOptions} from '../../utils/parenthood-options';
import {OriginInconsistenciesTableRow} from './table-row';

const compareFunction = (col1: string, col2: string): boolean => col1 !== col2 ;

// eslint-disable-next-line max-lines-per-function
export const getColumns = (
    hasSufficientPermissions: boolean,
    t: TFunction<'animals/OriginInconsistenciesTable'>,
    tCommon: TFunction<'common'>,
    getAnimalUrl: (value: number, row: OriginInconsistenciesTableRow) => string,
): Array<AnyTableColumn<OriginInconsistenciesTableRow>> => [
    {
        field: 'data',
        column: tCommon('note'),
        tooltip: tCommon('note'),
        filters: [
            {
                type: 'containsString',
                field: 'laboratoryNote',
                label: '',
            },
        ],
        type: 'reactNode',
        width: 57,
        verticalName: true,
    },
    {
        field: 'active',
        column: t('columnHeaders.active'),
        tooltip: t('columnHeadersTooltip.active'),
        type: 'boolean',
        verticalName: true,
        width: 57,
        sortable: true,
    },
    {
        field: 'hasProtocol',
        column: t('columnHeaders.hasProtocol'),
        tooltip: t('columnHeadersTooltip.hasProtocol'),
        type: 'boolean',
        verticalName: true,
        width: 57,
        sortable: true,
    },
    {
        field: 'dismissedFromIAR',
        column: t('columnHeaders.dismissedFromIAR'),
        tooltip: t('columnHeadersTooltip.dismissedFromIAR'),
        type: 'optionalBoolean',
        verticalName: true,
        width: 57,
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'earTag',
        column: tCommon('earTag'),
        tooltip: tCommon('earTag'),
        type: hasSufficientPermissions ? 'hyperlink' : 'string',
        valueToUrl: getAnimalUrl,
        verticalName: true,
        filterTypes: ['betweenEarTags'],
        width: 130,
        sortable: true,
    },
    {
        field: 'laboratoryNumber',
        column: t('columnHeaders.laboratoryNumber'),
        tooltip: t('columnHeadersTooltip.laboratoryNumber'),
        type: 'string',
        verticalName: true,
        width: 90,
        hasRightVerticalDivider: true,
        sortable: true,
        filterTypes: ['null'],
    },
    {
        column: t('columnHeaders.IGOrigin'),
        type: 'grouping',
        hasRightVerticalDivider: true,
        subColumns: [
            {
                field: 'fatherLinReg',
                column: t('columnHeaders.fatherLinReg'),
                tooltip: t('columnHeadersTooltip.fatherLinReg'),
                type: 'highlighted',
                compareTo: 'eskotFatherLinReg',
                comparator: compareFunction,
                verticalName: true,
                width: 90,
                sortable: true,
                filterTypes: ['null'],
            },
            {
                field: 'fatherEarTag',
                column: t('columnHeaders.fatherEarTag'),
                tooltip: t('columnHeadersTooltip.fatherEarTag'),
                type: 'highlighted',
                compareTo: 'eskotFatherEarTag',
                comparator: compareFunction,
                filterTypes: ['betweenEarTags', 'null'],
                width: 130,
                sortable: true,
            },
            {
                field: 'motherEarTag',
                column: t('columnHeaders.motherEarTag'),
                tooltip: t('columnHeadersTooltip.motherEarTag'),
                type: 'highlighted',
                compareTo: 'eskotMotherEarTag',
                comparator: compareFunction,
                filterTypes: ['betweenEarTags', 'null'],
                width: 130,
                sortable: true,
            },
            {
                field: 'parenthood',
                column: t('columnHeaders.parenthood'),
                tooltip: t('columnHeadersTooltip.parenthood'),
                type: 'reactNode',
                verticalName: true,
                width: 63,
                filters: [
                    {
                        type: 'select',
                        field: 'paternity',
                        label: t('columnHeaders.paternity'),
                        options: getParenthoodOptions(),
                    },
                    {
                        type: 'select',
                        field: 'maternity',
                        label: t('columnHeaders.maternity'),
                        options: getParenthoodOptions(),
                    },
                ],
                hasRightVerticalDivider: true,
                sortable: true,
            },
        ],
    },
    {
        column: t('columnHeaders.eSkotRec'),
        type: 'grouping',
        hasRightVerticalDivider: true,
        subColumns: [
            {
                field: 'eskotFatherLinReg',
                column: t('columnHeaders.fatherLinReg'),
                tooltip: t('columnHeadersTooltip.fatherLinReg'),
                type: 'highlighted',
                compareTo: 'fatherLinReg',
                comparator: compareFunction,
                width: 90,
                verticalName: true,
                sortable: true,
                filterTypes: ['null'],
            },
            {
                field: 'eskotFatherEarTag',
                column: t('columnHeaders.fatherEarTag'),
                tooltip: t('columnHeadersTooltip.fatherEarTag'),
                type: 'highlighted',
                compareTo: 'fatherEarTag',
                comparator: compareFunction,
                filterTypes: ['betweenEarTags', 'null'],
                width: 130,
                sortable: true,
            },
            {
                field: 'eskotMotherEarTag',
                column: t('columnHeaders.motherEarTag'),
                tooltip: t('columnHeadersTooltip.motherEarTag'),
                type: 'highlighted',
                compareTo: 'motherEarTag',
                comparator: compareFunction,
                filterTypes: ['betweenEarTags', 'null'],
                width: 130,
                hasRightVerticalDivider: true,
                sortable: true,
            },
        ],
    },
    {
        field: 'customerName',
        column: t('columnHeaders.customerName'),
        tooltip: t('columnHeadersTooltip.customerName'),
        type: 'string',
        flexibleWidth: true,
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'created',
        column: t('columnHeaders.createdAt'),
        tooltip: t('columnHeadersTooltip.createdAt'),
        type: 'date',
        dateFormat: 'date',
        width: 95,
        verticalName: true,
        sortable: true,
    },
    {
        field: 'correctOrigin',
        column: t('columnHeaders.correctOrigin'),
        tooltip: t('columnHeadersTooltip.correctOrigin'),
        type: 'option',
        options: getCorrectOriginOptions(),
        verticalName: true,
        width: 80,
        sortable: true,
        filterTypes: ['null'],
    },
    {
        field: 'reported',
        column: t('columnHeaders.reported'),
        tooltip: t('columnHeadersTooltip.reported'),
        type: 'date',
        dateFormat: 'date',
        verticalName: true,
        width: 90,
        sortable: true,
        filterTypes: ['null'],
    },
];
