import {ActionSettings, ToolbarButton, mergeObjects, TableDataRequest} from '@fl/cmsch-fe-library';
import {isNull} from 'lodash/fp';
import React, {FC, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {simpleAnimalsSelector} from 'animals/model/selector';
import {OriginInconsistency} from 'api/gen/OriginInconsistency';
import {State} from 'app/state';
import {useOurTranslation} from 'translations';
import {ConnectedTable} from 'utils/tables';

import {animalsAction} from '../../model';
import {DataCell} from '../DataCell';
import {MaternityDot} from '../MaternityDot';
import {PaternityDot} from '../PaternityDot';
import {getColumns} from './columns';
import {OriginInconsistenciesTableRow} from './table-row';

const dataTypeIndex = 0;
const oldIndex = 1;
const newIndex = 2;

interface Props {
    isRoleLaborer: boolean;
    isRoleAssociation: boolean;
    isFleckviehAssociation: boolean;
}

const getAnimalUrl = (_value: number, row: OriginInconsistenciesTableRow): string => `/animals/${row.animalId}`;
const allEskotEqualsNull = (row: OriginInconsistency): boolean =>
    row.eskotMotherEarTag === null && row.eskotFatherEarTag === null && row.eskotFatherLinReg === null;
const allPaternityEqualsNull = (row: OriginInconsistency): boolean =>
    row.motherEarTag === null && row.fatherEarTag === null && row.fatherLinReg === null;
const allEskotEqualsPaternity = (row: OriginInconsistency): boolean =>
    row.eskotMotherEarTag === row.motherEarTag
    && row.eskotFatherEarTag === row.fatherEarTag
    && row.eskotFatherLinReg === row.fatherLinReg;

// eslint-disable-next-line max-lines-per-function
export const OriginInconsistenciesTable: FC<Props> = props => {
    const {isRoleLaborer, isRoleAssociation, isFleckviehAssociation} = props;

    const {t, tCommon} = useOurTranslation('animals/OriginInconsistenciesTable');

    const inconsistenciesActionActive = useSelector((state: State) => state.animals.inconsistenciesActionActive);
    const selectedIds = useSelector(simpleAnimalsSelector.selectedOriginInconsistencyIds);

    const rowMapper = useCallback((row: OriginInconsistency): OriginInconsistenciesTableRow => mergeObjects(row, {
        parenthood: (
            <span>
                <PaternityDot paternity={row.paternity} />
                <MaternityDot maternity={row.maternity} />
            </span>
        ),
        data: (isRoleLaborer || isFleckviehAssociation
            ? (<DataCell laboratoryNote={row.laboratoryNote} />)
            : null
        ),
    }), [isRoleLaborer, isFleckviehAssociation]);

    const dispatch = useDispatch();
    const getData = useCallback((data: Opt<TableDataRequest<OriginInconsistency>>) => {
        dispatch(animalsAction.getOriginInconsistencies(data));
    }, [dispatch]);

    const exportToCSV = useCallback(() => {
        dispatch(animalsAction.exportOriginInconsistenciesToCSV());
    }, [dispatch]);

    const acceptInconsistency = useCallback((rowId: number) => {
        dispatch(animalsAction.acceptOriginInconsistency(rowId));
    }, [dispatch]);

    const sendReport = useCallback((rowId: number) => {
        dispatch(animalsAction.sendOriginInconsistencyReport(rowId));
    }, [dispatch]);

    const exportReport = useCallback((rowId: number) => {
        dispatch(animalsAction.exportOriginInconsistencyReport(rowId));
    }, [dispatch]);

    const toggleActivity = useCallback((rowId: number, row: OriginInconsistenciesTableRow) => {
        dispatch(row.active ? animalsAction.markAsInactive(rowId) : animalsAction.markAsActive(rowId, row.earTag));
    }, [dispatch]);

    const onSelectedIdsChange = useCallback((ids: Array<number>) => {
        dispatch(animalsAction.setSelectedOriginInconsistencyIds(ids));
    }, [dispatch]);

    const getMailActionTitle = useCallback((id: number, row: OriginInconsistenciesTableRow): string => {
        if (!row.active) {
            return t('tableActions.inactive');
        } else if (allEskotEqualsPaternity(row)) {
            return t('tableActions.dataMatch');
        } else if (allPaternityEqualsNull(row)) {
            return t('tableActions.noDataFromPaternity');
        } else if (inconsistenciesActionActive.includes(id)) {
            return t('tableActions.otherActionActive');
        }

        return t('tableActions.reported');
    }, [inconsistenciesActionActive, t]);

    const getAcceptActionTitle = useCallback((id: number, row: OriginInconsistenciesTableRow): string => {
        if (!row.active) {
            return t('tableActions.inactive');
        } else if (allEskotEqualsPaternity(row)) {
            return t('tableActions.dataMatch');
        } else if (allEskotEqualsNull(row)) {
            return t('tableActions.noDataFromEskot');
        } else if (inconsistenciesActionActive.includes(id)) {
            return t('tableActions.otherActionActive');
        }

        return t('tableActions.accepted');
    }, [inconsistenciesActionActive, t]);

    const exportPDFDisabledTitle = useCallback((id: number, row: OriginInconsistenciesTableRow) => {
        if (row.active && isNull(row.reported)) {
            return t('tableActions.notYetSent');
        }
        if (inconsistenciesActionActive.includes(id)) {
            return t('tableActions.otherActionActive');
        }

        return t('tableActions.cantExportPDF');
    }, [inconsistenciesActionActive, t]);

    const disableAcceptAction = useCallback((id: number, row: OriginInconsistenciesTableRow): boolean =>
        !row.active || row.reported !== null || !(isRoleLaborer || isFleckviehAssociation) || allEskotEqualsNull(row)
        || inconsistenciesActionActive.includes(id) || allEskotEqualsPaternity(row)
    , [inconsistenciesActionActive, isFleckviehAssociation, isRoleLaborer]);
    const disableSendMailAction = useCallback((id: number, row: OriginInconsistenciesTableRow): boolean => !row.active
        || row.reported !== null
        || !(isRoleLaborer || isFleckviehAssociation)
        || allPaternityEqualsNull(row)
        || inconsistenciesActionActive.includes(id)
        || allEskotEqualsPaternity(row)
    , [inconsistenciesActionActive, isFleckviehAssociation, isRoleLaborer]);
    const disableExportPdfAction = useCallback((id: number, row: OriginInconsistenciesTableRow): boolean =>
        isNull(row.reported) || !(isRoleLaborer || isFleckviehAssociation) || inconsistenciesActionActive.includes(id)
    , [inconsistenciesActionActive, isFleckviehAssociation, isRoleLaborer]);
    const disableToggleActivityAction = useCallback((id: number, row: OriginInconsistenciesTableRow): boolean =>
        row.correctOrigin === 'ESKOT' || inconsistenciesActionActive.includes(id)
    , [inconsistenciesActionActive]);

    const acceptConfirmTitle = useCallback((_id: number, row: OriginInconsistenciesTableRow) => {
        const {
            eskotFatherEarTag,
            eskotFatherLinReg,
            eskotMotherEarTag,
            fatherEarTag,
            fatherLinReg,
            motherEarTag,
            earTag,
        } = row;

        const changesInput: Array<[string | null, string | null, string | null]> = [
            [t('columnHeaders.fatherLinReg'), fatherLinReg, eskotFatherLinReg],
            [t('columnHeaders.fatherEarTag'), fatherEarTag, eskotFatherEarTag],
            [t('columnHeaders.motherEarTag'), motherEarTag, eskotMotherEarTag],
        ];
        const changes = changesInput
            .filter(xs => xs[oldIndex] !== xs[newIndex] && !isNull(xs[newIndex]))
            .map(xs => t(isNull(xs[oldIndex])
                ? 'tableActions.willBeSetTo'
                : 'tableActions.willBeReplaced', {data: xs[dataTypeIndex], old: xs[oldIndex], new: xs[newIndex]}));

        return t('tableActions.confirmAcceptChange', {UZ: earTag, changes: changes.join('\n')});
    }, [t]);
    const sendEmailConfirmTitle = useCallback((_id: number, row: OriginInconsistenciesTableRow) =>
        t('tableActions.confirmSendEmail', {UZ: row.earTag})
    , [t]);
    const toggleActivityConfirmTitle = useCallback((_id: number, row: OriginInconsistenciesTableRow) =>
        t(row.active ? 'tableActions.confirmMarkAsInactive' : 'tableActions.confirmMarkAsActive', {UZ: row.earTag})
    , [t]);
    const toggleActivityTitle = useCallback((_id: number, row: OriginInconsistenciesTableRow) =>
        t(row.active ? 'tableActions.markInactive' : 'tableActions.markActive', {UZ: row.earTag})
    , [t]);
    const toggleActivityDanger = useCallback((_id: number, row: OriginInconsistenciesTableRow) => row.active, []);

    const actionSettings: ActionSettings<OriginInconsistenciesTableRow> = useMemo(
        () => ({
            selectedIds,
            onSelectedIdsChange,
            extraActions: [
                {
                    id: 'confirm',
                    title: t('tableActions.confirm'),
                    icon: 'checkCircleFilled',
                    confirmClick: true,
                    confirmTitle: acceptConfirmTitle,
                    confirmPlacement: 'topRight',
                    disabledTitle: getAcceptActionTitle,
                    callback: acceptInconsistency,
                    disabled: disableAcceptAction,
                },
                {
                    id: 'sendEmail',
                    title: t('tableActions.sendEmail'),
                    icon: 'mailFilled',
                    confirmClick: true,
                    confirmTitle: sendEmailConfirmTitle,
                    confirmPlacement: 'topRight',
                    disabledTitle: getMailActionTitle,
                    callback: sendReport,
                    disabled: disableSendMailAction,
                },
                {
                    id: 'exportPDF',
                    title: t('tableActions.exportPDF'),
                    icon: 'saveFilled',
                    disabledTitle: exportPDFDisabledTitle,
                    callback: exportReport,
                    disabled: disableExportPdfAction,
                },
                {
                    id: 'toggleActivity',
                    title: toggleActivityTitle,
                    icon: 'powerOffOutlined',
                    confirmClick: true,
                    confirmTitle: toggleActivityConfirmTitle,
                    confirmPlacement: 'topRight',
                    disabledTitle: t('tableActions.cannotMark'),
                    callback: toggleActivity,
                    disabled: disableToggleActivityAction,
                    danger: toggleActivityDanger,
                },
            ],
        }),
        [
            selectedIds,
            onSelectedIdsChange,
            t,
            acceptConfirmTitle,
            getAcceptActionTitle,
            acceptInconsistency,
            disableAcceptAction,
            sendEmailConfirmTitle,
            getMailActionTitle,
            sendReport,
            disableSendMailAction,
            exportPDFDisabledTitle,
            exportReport,
            disableExportPdfAction,
            toggleActivityTitle,
            toggleActivityConfirmTitle,
            toggleActivity,
            disableToggleActivityAction,
            toggleActivityDanger,
        ],
    );

    const headerButtons = useMemo((): Array<ToolbarButton> => [
        {
            disabled: false,
            icon: 'exportOutlined',
            onClick: exportToCSV,
            title: t('tableActions.toCSV'),
            visuals: 'primary',
            testId: 'exportToCSV',
        },
    ], [exportToCSV, t]);

    return (
        <ConnectedTable
            tableId="originInconsistencies"
            columns={getColumns(isRoleLaborer, t, tCommon, getAnimalUrl)}
            rowDataToTableRowMapper={rowMapper}
            rowDataPageSelector={simpleAnimalsSelector.pageTableData}
            onFetchTableData={getData}
            actionSettings={isRoleLaborer || isFleckviehAssociation ? actionSettings : undefined}
            headerButtons={isRoleLaborer || isRoleAssociation ? headerButtons : undefined}
            rowActionsOnRight
            height={4000}
            paginationPosition="both"
            showFulltextFilter
        />
    );
};
