import {Opt} from 'ts-opt';

import {CategoryDetails} from 'api/gen/CategoryDetails';
import {PageSnpView} from 'api/gen/PageSnpView';
import {State} from 'app/state';

export const simpleSnpManagementSelector = {
    pageTableData: ({snpManagement}: State): Opt<PageSnpView> => snpManagement.pageTableData,
    selected: ({snpManagement}: State): Array<number> => snpManagement.selected,
    categories: ({snpManagement}: State): Opt<Array<CategoryDetails>> => snpManagement.categories,
    isCreateCategoryVisible: ({snpManagement}: State): boolean => snpManagement.isCreateCategoryVisible,
    isCreateSnpVisible: ({snpManagement}: State): boolean => snpManagement.isCreateSnpVisible,
    tableIsLoading: ({snpManagement}: State): boolean => snpManagement.tableIsLoading,
};
