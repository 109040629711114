// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from '@fl/cmsch-fe-library';
import {StringPatternSchema} from '@fl/cmsch-fe-library';


// A bull's line-registry.
// Example: "NXB-481"

export const lineRegistryRegexGen = (): RegExp => new RegExp("^(([A-Z]{1,3})|([0-9]{3}))-([0-9]{3})$");

export const lineRegistryMinLength = 1;
export const lineRegistryMaxLength = 255;

export const LineRegistrySchema = t.refinement(StringPatternSchema<LineRegistry>(lineRegistryRegexGen()), n => n.length >= lineRegistryMinLength && n.length <= lineRegistryMaxLength, 'LineRegistry');

export type LineRegistry = string;
