import {createSelector} from 'reselect';
import {Opt} from 'ts-opt';

import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {OrderDetailsForProtocol} from 'api/gen/OrderDetailsForProtocol';
import {OrderSearch} from 'api/gen/OrderSearch';
import {PageOrder} from 'api/gen/PageOrder';
import {User} from 'api/gen/User';
import {State} from 'app/state';
import {tCommon} from 'translations';
import {formHelpers} from 'utils/forms';

import {LastOrderUserDetails} from '../../../api/gen/LastOrderUserDetails';
import {OrderAnimalDetails} from '../../../api/gen/OrderAnimalDetails';
import {AnimalDetailsFormSectionValues} from '../components/AnimalDetailsForm/animal-details-form-section-values';
import {AnimalsDetailsTableType} from '../components/NewBulkForm/new-bulk-order-form-values';
import {ConfirmDialogData} from '../types/confirm-dialog-data';
import {OrderState} from './state';

export const simpleFormSelector = {
    newOrderBulkAnimalsDetails: (state: State): Array<AnimalsDetailsTableType> | null =>
        formHelpers.formValues('newBulkOrder')(state).prop('animalsDetails').orNull(),
    newOrderAnimal: (state: State): AnimalDetailsFormSectionValues | null =>
        formHelpers.formValues('newOrder')(state).prop('animalDetails').orNull(),
    newOrderAsyncErrors: formHelpers.getAsyncErrors('newOrder'),
    orderBarcode: (state: State): string | null =>
        formHelpers.formValues('newOrder')(state).prop('barcode').orNull(),
    newOrderSyncErrors: formHelpers.getSyncErrors('newOrder'),
    bulkOrderSampleTypeId: (state: State): number | null =>
        formHelpers.formValues('newBulkOrder')(state).prop('sampleTypeId').orNull(),
    bulkOrderTypesId: (state: State): Array<number> | null =>
        formHelpers.formValues('newBulkOrder')(state).prop('orderTypeIds').orNull(),
    bulkOrderFormValues: formHelpers.formValues('newBulkOrder'),
};

export const simpleOrdersSelector = {
    ordersPage: ({orders}: State): Opt<PageOrder> => orders.ordersPage,
    addAnimalModalOpen: ({orders}: State): boolean => orders.modalsVisibility.includes('addAnimal'),
    foundOrders: ({orders}: State): Opt<Array<OrderSearch>> => orders.foundOrders,
    isCinFetching: ({orders}: State): boolean => orders.cinIsFetching,
    forceDownloadConfirmed: ({orders}: State): boolean => orders.forceDownloadConfirmed,
    breeders: ({orders}: State): Opt<Array<User>> => orders.breeders,
    orders: ({orders}: State): OrderState => orders,
    confirmDialog: ({orders}: State): Opt<ConfirmDialogData> => orders.confirmDialog,
    isValidating: ({orders}: State): boolean => orders.isValidating,
    motherAlreadyAnalyzed: ({orders}: State): boolean => orders.motherAlreadyAnalyzed,
    fatherAlreadyAnalyzed: ({orders}: State): boolean => orders.fatherAlreadyAnalyzed,
    isNewOrderAnimalValid: ({orders}: State): boolean => orders.isNewOrderAnimalValid,
    newOrderEarNumberWarning: ({orders}: State): Opt<string> => orders.newOrderEarNumberWarning,
    isAnimalDetailEditing: ({orders}: State): boolean => orders.isAnimalDetailEditing,
    customers: ({orders}: State): Opt<Array<OrderCustomerDetails>> => orders.customers,
    protocolData: ({orders}: State): Opt<OrderDetailsForProtocol> => orders.protocolData,
    userDetailsFromCIN: ({orders}: State): Opt<LastOrderUserDetails> => orders.userDetailsFromCIN,
    foundAnimal: ({orders}: State): Opt<OrderAnimalDetails> => orders.foundAnimal,
};

export const animalsDetailsTableRow = createSelector(
    simpleFormSelector.newOrderBulkAnimalsDetails,
    animalsDetails => (animalsDetails ?? []).map(a => ({
        // id have to be uniqe, only for FE pourpose
        id: a.id || Date.now(),
        animal: a.earTag,
        barcode: a.barcode,
        sex: tCommon(a.sex === 'MALE' ? 'sex.male' : 'sex.female'),
        breed: a.breed,
        dob: a.dob,
        fatherLinReg: a.fatherLinReg,
        motherEarTag: a.motherEarTag,
    })));

export const editOrderCustomerFormValuesSelector = createSelector(
    (state: State) => state,
    formHelpers.formValues('editOrderCustomer'),
);

export const newBulkOrderFormValuesSelector = createSelector(
    (state: State) => state,
    formHelpers.formValues('newBulkOrder'),
);
