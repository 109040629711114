import {extractFormErrorsFromResponse, putAll, showBeError, showSuccess, takeLatestF} from '@fl/cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {convertTableDataParamsToDto, simpleTableSelector} from 'utils/tables';

import {orderAction, UpdateCustomersEmailByFilterAction} from '../action';

const te = t('orders/sagas');

function* execute(action: UpdateCustomersEmailByFilterAction): SagaIterator {
    const {email, selectedOrders, withoutSelected} = action.payload;

    const tableState = yield* select(simpleTableSelector.tableState('orders'));
    const tableDataParams = opt(tableState).prop('tableDataParams').orCrash('missing table data params');

    const {filters} = convertTableDataParamsToDto({tableDataParams});

    const response = yield* call(Api.updateCustomersEmail, {
        emailDetails: {email},
        tableFilters: {
            excludedOrderIds: withoutSelected ? selectedOrders : [],
            filters,
        },
    });

    if (response.isSuccess) {
        yield* put(formHelpers.stopSubmit('bulkEditOrderEmail'));
        yield* put(orderAction.resetBulkEditOrderFilter());
        yield* put(orderAction.getOrders(opt({action: 'refresh'})));
        yield* put(showSuccess(te('updateCustomersEmail'), te('success')));
    } else {
        yield* put(formHelpers.stopSubmit('bulkEditOrderEmail', extractFormErrorsFromResponse(response)));
        yield putAll(showBeError(response, te('updateCustomersEmail')));
    }
}

export function* updateCustomersEmailByFilterSaga(): SagaIterator {
    yield takeLatestF('order/UPDATE_CUSTOMERS_EMAIL_BY_FILTER', execute);
}
