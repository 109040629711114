import {ActionSettings, Table, TableColumn, Button} from '@fl/cmsch-fe-library';
import React, {Fragment, FC, memo, useCallback, useEffect, useMemo} from 'react';
import {find, Opt} from 'ts-opt';

import {CodeTableItem} from 'api/gen/CodeTableItem';
import {CodeTableValidation} from 'api/gen/CodeTableValidation';
import {NewCodeTableItem} from 'api/gen/NewCodeTableItem';
import {DialsModals} from 'dials/types/dials-modals';
import {useOurTranslation} from 'translations';

import {SampleTypesModal} from './sample-types-modal';

interface Props {
    loading: boolean;
    modalsVisibility: Array<DialsModals>;
    sampleTypes: Opt<Array<CodeTableItem>>;
    sampleTypeIdBeingEdited: Opt<number>;
    toggleModalVisibility(id: DialsModals): void;
    getSampleTypes(): void;
    startEditingSampleType(sampleType: CodeTableItem): void;
    updateSampleType(id: number, sampleType: NewCodeTableItem): void;
    sampleTypeEditingCancelled(): void;
    createSampleType(sampleType: NewCodeTableItem): void;
    validateSampleType(data: CodeTableValidation): void;
    resetSampleTypes(): void;
}

const SampleTypesBase: FC<Props> = props => {
    const {
        loading,
        modalsVisibility,
        sampleTypes,
        sampleTypeIdBeingEdited,
        toggleModalVisibility,
        getSampleTypes,
        startEditingSampleType,
        updateSampleType,
        sampleTypeEditingCancelled,
        createSampleType,
        validateSampleType,
        resetSampleTypes,
    } = props;

    useEffect(() => {
        getSampleTypes();
    }, [getSampleTypes]);

    const {t} = useOurTranslation('dials/sampleTypes');

    const toggleSampleModalVisibility = useCallback(() =>
        toggleModalVisibility('sampleType')
    , [toggleModalVisibility]);

    const onEdit = useCallback((id: number) =>
        sampleTypes.chain(find(x => x.id === id))
            .onSome(sampleTypeToEdit => {
                startEditingSampleType(sampleTypeToEdit);
                toggleSampleModalVisibility();
            })
    , [sampleTypes, startEditingSampleType, toggleSampleModalVisibility]);

    const onEditCancel = useCallback(() => {
        sampleTypeEditingCancelled();
        toggleSampleModalVisibility();
    }, [sampleTypeEditingCancelled, toggleSampleModalVisibility]);

    const onUpdate = useCallback((sampleType: NewCodeTableItem) => {
        sampleTypeIdBeingEdited.onSome(x => updateSampleType(x, sampleType));
    }, [sampleTypeIdBeingEdited, updateSampleType]);

    const columns = useMemo((): Array<TableColumn<CodeTableItem>> => [
        {
            field: 'value',
            type: 'string',
            column: t('value'),
            tooltip: t('value'),
            disableFilter: true,
        },
    ], [t]);

    const actionSettings = useMemo((): ActionSettings<CodeTableItem> => ({
        onEdit,
    }), [onEdit]);

    return (
        <Fragment>
            <div className="d-flex justify-content-end mb-2">
                <Button
                    visuals="primary"
                    icon="plusOutlined"
                    onClick={toggleSampleModalVisibility}
                >
                    {t('createNew')}
                </Button>
            </div>
            <Table
                tableId="sampleTypes"
                columns={columns}
                rows={sampleTypes.orElse([])}
                actionSettings={actionSettings}
                loading={loading}
                onUnmount={resetSampleTypes}
            />
            <SampleTypesModal
                visible={modalsVisibility.includes('sampleType')}
                sampleTypeIdBeingEdited={sampleTypeIdBeingEdited}
                onSubmitEdit={onUpdate}
                onSubmitCreate={createSampleType}
                onCancel={sampleTypeIdBeingEdited.isEmpty ? toggleSampleModalVisibility : onEditCancel}
                validateSampleType={validateSampleType}
            />
        </Fragment>
    );
};

export const SampleTypes = memo(SampleTypesBase);
