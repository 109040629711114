import {ActionSettings, formatProbability, Table, TableColumn} from '@fl/cmsch-fe-library';
import React, {FC, memo} from 'react';

import {ParentSearchResultDetails} from 'api/gen/ParentSearchResultDetails';
import {useOurTranslation} from 'translations';

interface Props {
    result: Array<ParentSearchResultDetails>;
}

interface ParentSearchTableType extends ParentSearchResultDetails {
    earNumber: string;
    probabilityWithPercentage: string;
    id: number;
}

const goToAnimalDetail = (_id: number, row: ParentSearchTableType): string => `/animals/${row.animalIds.id}`;

const ParentSearchResultTableBase: FC<Props> = props => {
    const {result} = props;

    const {t, tCommon} = useOurTranslation('parentSearch/Table');

    const columns: Array<TableColumn<ParentSearchTableType>> = [
        {
            field: 'earNumber',
            type: 'string',
            column: tCommon('earTag'),
            tooltip: tCommon('earTag'),
            disableFilter: true,
        },
        {
            field: 'probabilityWithPercentage',
            type: 'string',
            column: t('probability'),
            tooltip: t('probability'),
            disableFilter: true,
        },
        {
            field: 'numOfProcessedGenes',
            type: 'number',
            column: t('numOfProcessedGenes'),
            tooltip: t('numOfProcessedGenes'),
            disableFilter: true,
        },
    ];

    const actionSettings: ActionSettings<ParentSearchTableType> = {
        extraActions: [
            {
                id: 'detail',
                href: goToAnimalDetail,
                icon: 'infoCircleOutlined',
                title: t('showAnimalDetail'),
            },
        ],
    };

    const rows = result.map((x): ParentSearchTableType => ({
        animalIds: x.animalIds,
        earNumber: x.animalIds.sampleId,
        id: x.animalIds.id,
        numOfProcessedGenes: x.numOfProcessedGenes,
        probability: x.probability,
        probabilityWithPercentage: `${formatProbability(x.probability)}%`,
    }));

    return (
        <Table
            tableId="parentSearch"
            columns={columns}
            rows={rows}
            actionSettings={actionSettings}
            scroll={{x: true}}
        />
    );
};

export const ParentSearchResultTable = memo(ParentSearchResultTableBase);
