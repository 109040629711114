import {none, Opt} from 'ts-opt';

import {PageGenotypeExport} from 'api/gen/PageGenotypeExport';

export interface GenotypeExportsState {
    pageGenotypeExports: Opt<PageGenotypeExport>;
}

export const initialGenotypeExportsState: GenotypeExportsState = {
    pageGenotypeExports: none,
};
