import {ActionSettings, Table, TableColumn, Button} from '@fl/cmsch-fe-library';
import {map} from 'lodash/fp';
import React, {Fragment, FC, memo, useCallback, useEffect, useMemo} from 'react';
import {find, Opt} from 'ts-opt';

import {NewOrderTypeItem} from 'api/gen/NewOrderTypeItem';
import {OrderTypeItem} from 'api/gen/OrderTypeItem';
import {OrderTypeValidation} from 'api/gen/OrderTypeValidation';
import {DialsModals} from 'dials/types/dials-modals';
import {useOurTranslation} from 'translations';

import {OrderTypesModal} from './order-types-modal';

interface Props {
    loading: boolean;
    modalsVisibility: Array<DialsModals>;
    orderTypes: Opt<Array<OrderTypeItem>>;
    orderTypeIdBeingEdited: Opt<number>;
    toggleModalVisibility(id: DialsModals): void;
    getOrderTypes(): void;
    startEditingOrderType(orderType: OrderTypeItem): void;
    updateOrderType(id: number, orderType: NewOrderTypeItem): void;
    orderTypeEditingCancelled(): void;
    createOrderType(orderType: NewOrderTypeItem): void;
    validateOrderType(data: OrderTypeValidation): void;
    resetOrderTypes(): void;
}

interface OrderTypeItemTableType extends OrderTypeItem {
    priceWithCurrency: string;
    extraChargeWithCurrency: string;
}

const OrderTypesBase: FC<Props> = props => {
    const {
        loading,
        modalsVisibility,
        orderTypes,
        orderTypeIdBeingEdited,
        toggleModalVisibility,
        startEditingOrderType,
        updateOrderType,
        orderTypeEditingCancelled,
        createOrderType,
        getOrderTypes,
        validateOrderType,
        resetOrderTypes,
    } = props;

    useEffect(() => {
        getOrderTypes();
    }, [getOrderTypes]);

    const {t} = useOurTranslation('dials/orderTypes');

    const toggleOrderModalVisibility = useCallback(() =>
        toggleModalVisibility('orderType')
    , [toggleModalVisibility]);

    const onEdit = useCallback((id: number) =>
        orderTypes.chain(find(x => x.id === id))
            .onSome(orderTypeToEdit => {
                startEditingOrderType(orderTypeToEdit);
                toggleOrderModalVisibility();
            })
    , [orderTypes, startEditingOrderType, toggleOrderModalVisibility]);

    const onEditCancel = useCallback(() => {
        orderTypeEditingCancelled();
        toggleOrderModalVisibility();
    }, [orderTypeEditingCancelled, toggleOrderModalVisibility]);

    const onUpdate = useCallback((data: NewOrderTypeItem) => {
        const {code, extraCharge, price, value} = data;
        orderTypeIdBeingEdited.onSome(x => updateOrderType(x, {code, extraCharge, price, value}));
    }, [orderTypeIdBeingEdited, updateOrderType]);

    const columns = useMemo((): Array<TableColumn<OrderTypeItemTableType>> => [
        {
            field: 'priceWithCurrency',
            type: 'string',
            column: t('price'),
            tooltip: t('price'),
            width: 100,
            disableFilter: true,
        },
        {
            field: 'extraChargeWithCurrency',
            type: 'string',
            column: t('extraCharge'),
            tooltip: t('extraCharge'),
            width: 100,
            disableFilter: true,
        },
        {
            field: 'code',
            type: 'string',
            column: t('code'),
            tooltip: t('code'),
            width: 80,
            disableFilter: true,
        },
        {
            field: 'value',
            type: 'string',
            column: t('description'),
            tooltip: t('description'),
            disableFilter: true,
        },
    ], [t]);

    const actionSettings = useMemo((): ActionSettings<OrderTypeItemTableType> => ({
        onEdit,
    }), [onEdit]);

    const rows = useMemo(
        () => orderTypes.map(map((x): OrderTypeItemTableType => ({
            code: x.code,
            extraCharge: x.extraCharge,
            extraChargeWithCurrency: `${x.extraCharge || '0'} Kč`,
            id: x.id,
            price: x.price,
            priceWithCurrency: `${x.price || '0'} Kč`,
            value: x.value,
        }))).orElse([]),
        [orderTypes],
    );

    return (
        <Fragment>
            <div className="d-flex justify-content-end mb-2">
                <Button
                    visuals="primary"
                    icon="plusOutlined"
                    onClick={toggleOrderModalVisibility}
                >
                    {t('createNew')}
                </Button>
            </div>
            <Table
                tableId="orderTypes"
                columns={columns}
                rows={rows}
                actionSettings={actionSettings}
                loading={loading}
                scroll={{x: true}}
                noEllipsis
                onUnmount={resetOrderTypes}
            />
            <OrderTypesModal
                visible={modalsVisibility.includes('orderType')}
                orderTypeIdBeingEdited={orderTypeIdBeingEdited}
                onSubmitEdit={onUpdate}
                onSubmitCreate={createOrderType}
                onCancel={orderTypeIdBeingEdited.isEmpty ? toggleOrderModalVisibility : onEditCancel}
                validateOrderType={validateOrderType}
            />
        </Fragment>
    );
};

export const OrderTypes = memo(OrderTypesBase);
