import {putAll, takeLatestF} from '@fl/cmsch-fe-library';
import {map} from 'lodash/fp';
import {SagaIterator} from 'redux-saga';
import {put, call, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {appAction} from 'app/model/action';
import {t} from 'translations';
import {showBeError} from 'utils/show-be-error';

import {GetUserLastOrderAction, orderAction} from '../action';
import {simpleOrdersSelector} from '../selector';

function* execute({payload: {cin, identifier}}: GetUserLastOrderAction): SagaIterator {
    yield* put(appAction.addLoadingContent('getUserLastOrder'));
    const response = yield* call(Api.getLastOrderData, {cin});

    if (response.isSuccess) {
        yield* put(orderAction.setUserDetailsFromCIN(cin ? response.data : null));
        const breeders = yield* select(simpleOrdersSelector.breeders);
        const breederIds = breeders.map(map(x => x.id)).orElse([]);
        yield* put(orderAction.getUserLastOrderSuccess(response.data, cin || null, breederIds, identifier));
    } else {
        yield* put(orderAction.setUserDetailsFromCIN(null));
        yield putAll(showBeError(response, t('orders/sagas')('getUserLastOrder')));
    }
    yield* put(appAction.removeLoadingContent('getUserLastOrder'));
}

export function* getUserLastOrderSaga(): SagaIterator {
    yield takeLatestF('order/GET_USER_LAST_ORDER', execute);
}
