import {extractFormErrorsFromResponse, NullableKeys, putAll, takeLatestF} from '@fl/cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {AnimalUpdate} from 'api/gen/AnimalUpdate';
import {Api} from 'api/gen/Api';
import {State} from 'app/state';
import {t} from 'translations';
import {formHelpers} from 'utils/forms';
import {showBeError} from 'utils/show-be-error';

import {AnimalEditFormValues} from '../../components/AnimalEditForm/animal-edit-form-values';
import {UpdateAnimal} from '../action';

const prepareAnimalUpdate = (values: NullableKeys<AnimalEditFormValues>): AnimalUpdate => ({
    breed: opt(values.breed).orCrash('missing breed'),
    dob: values.dob,
    fathersRegistry: values.fathersRegistry,
    fathersSampleId: values.fathersSampleId,
    mothersSampleId: values.mothersSampleId,
    name: values.name,
    registry: values.registry,
    sampleId: opt(values.sampleId).orCrash('missing sampleId'),
    sex: opt(values.sex).orCrash('missing sex'),
    twin: opt(values.twin).orCrash('missing twin'),
    laboratoryNote: values.laboratoryNote,
});

const getEditAnimalFormValues = (state: State): NullableKeys<AnimalEditFormValues> =>
    formHelpers.formValues('animalEdit')(state).orCrash('missing animalEdit values');

function* execute({payload: {animalId}}: UpdateAnimal): SagaIterator {
    yield* put(formHelpers.startSubmit('animalEdit'));

    const values = yield* select(getEditAnimalFormValues);
    const response = yield* call(Api.updateAnimal, prepareAnimalUpdate(values), {animalId});

    if (response.isSuccess) {
        yield* put(routerActions.push('/animals'));
        yield* put(formHelpers.stopSubmit('animalEdit'));
        yield* put(formHelpers.reset('animalEdit'));
    } else {
        yield putAll(showBeError(response, t('animals/sagaMessages')('updateAnimalError')));
        yield* put(formHelpers.stopSubmit('animalEdit', extractFormErrorsFromResponse(response)));
    }
}

export function* updateAnimalSaga(): SagaIterator {
    yield takeLatestF('animals/UPDATE_ANIMAL', execute);
}
