import {TableDataRequest} from '@fl/cmsch-fe-library';
import {Opt} from 'ts-opt';

import {Category} from 'api/gen/Category';
import {CategoryDetails} from 'api/gen/CategoryDetails';
import {PageSnpView} from 'api/gen/PageSnpView';
import {Snp} from 'api/gen/Snp';
import {SnpView} from 'api/gen/SnpView';

export const snpActions = {
    setShowCreateSnp: () => ({
        type: 'snpManagements/TOGGLE_SHOW_CREATE_SNP',
    }) as const,
    setShowCreateSnpCategory: () => ({
        type: 'snpManagements/TOGGLE_SHOW_CREATE_CATEGORY',
    }) as const,
    setSnpTableData: (tableData: Opt<PageSnpView>) => ({
        type: 'snpManagements/SET_TABLE_DATA',
        payload: {tableData},
    }) as const,
    loadSnp: (tableDataRequest: Opt<TableDataRequest<SnpView>>) => ({
        type: 'snpManagement/LOAD_SNP',
        payload: {tableDataRequest},
    }) as const,
    loadCategories: () => ({
        type: 'snpManagement/LOAD_CATEGORIES',
    }) as const,
    loadCategoriesSuccess: (category: Array<CategoryDetails>) => ({
        type: 'snpManagement/CATEGORIES_SUCCESS',
        payload: {category},
    }) as const,
    regenerateSnp: () => ({
        type: 'snpManagement/REGENERATE_SNP',
    }) as const,
    createSnp: ({categoryId, name}: Snp) => ({
        type: 'snpManagement/CREATE_SNP',
        payload: {categoryId, name},
    }) as const,
    createSnpCategory: (name: Category) => ({
        type: 'snpManagement/CREATE_CATEGORY',
        payload: name,
    }) as const,
    closeForm: () => ({
        type: 'snpManagement/CLOSE_FORM',
    }) as const,
    selectIds: (ids: Array<number>) => ({
        type: 'snpManagement/CHANGE_SELECTED_IDS',
        payload: {ids},
    }) as const,
    deselectAll: () => ({
        type: 'snpManagement/DESELECT_ALL',
    }) as const,
    validateSnpName: (name: string) => ({
        type: 'snpManagement/VALIDATE_NAME',
        payload: name,
    }) as const,
};

export type SetShowCreateSnpAction = ReturnType<typeof snpActions.setShowCreateSnp>;
export type SetShowCreateSnpCategoryAction = ReturnType<typeof snpActions.setShowCreateSnpCategory>;
export type SetSnpTableDataAction = ReturnType<typeof snpActions.setSnpTableData>;
export type LoadSnpAction = ReturnType<typeof snpActions.loadSnp>;
export type CategoriesAction = ReturnType<typeof snpActions.loadCategories>;
export type CategoriesSuccessAction = ReturnType<typeof snpActions.loadCategoriesSuccess>;
export type RegenerateSnpAction = ReturnType<typeof snpActions.regenerateSnp>;
export type CreateSnpAction = ReturnType<typeof snpActions.createSnp>;
export type CreateSnpCategoryAction = ReturnType<typeof snpActions.createSnpCategory>;
export type SelectAction = ReturnType<typeof snpActions.selectIds>;
export type CloseFormAction = ReturnType<typeof snpActions.closeForm>;
export type DeselectAllAction = ReturnType<typeof snpActions.deselectAll>;
export type ValidateSnpNameAction = ReturnType<typeof snpActions.validateSnpName>;

export type SnpActions =
    | SetShowCreateSnpAction
    | SetShowCreateSnpCategoryAction
    | SetSnpTableDataAction
    | LoadSnpAction
    | CategoriesAction
    | CategoriesSuccessAction
    | RegenerateSnpAction
    | CreateSnpAction
    | CreateSnpCategoryAction
    | SelectAction
    | CloseFormAction
    | DeselectAllAction
    | ValidateSnpNameAction
;
