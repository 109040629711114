import {Options, TableColumn} from '@fl/cmsch-fe-library';

import {Snp} from 'api/gen/Snp';
import {TFunction} from 'translations';

export const columns = (
    t: TFunction<'snpManagement/SnpTable'>,
    categories: Options<number>,
): Array<TableColumn<Snp>
> => [
    {
        field: 'name',
        column: t('columnHeaders.name'),
        tooltip: t('columnHeaders.name'),
        type: 'string',
    },
    {
        field: 'categoryId',
        column: t('columnHeaders.category'),
        tooltip: t('columnHeaders.category'),
        type: 'option',
        options: categories,
        filterTypes: ['multiSelectNumber'],
    },
];
