import {classNames} from '@fl/cmsch-fe-library';
import React, {FC, useMemo} from 'react';

import {Ant, AntDropdown} from 'common/ant';
import {ICONS, IconName} from 'common/icons';
import {useOurTranslation} from 'translations';

import {DropdownOptions, Option} from './dropdown-options';

import styles from './styles.sass';

interface Props {
    selectedSize: number;
    filterSize: number;
    title: string;
    icon: IconName;
    loading?: boolean;
    onOption(option: Option): void;
}
export const Dropdown: FC<Props> = props => {
    const {selectedSize, filterSize, loading, icon, title, onOption} = props;
    const noItems = filterSize === 0 && selectedSize === 0;

    const {t} = useOurTranslation('orders/ListActions');

    const dropdown = useMemo(() => (
        <DropdownOptions
            t={t}
            selected={selectedSize}
            filtered={filterSize}
            onOption={onOption}
        />
    ), [t, selectedSize, filterSize, onOption]);

    return (
        <div
            className={classNames(styles.listAction, 'mb-2')}
            data-test-id="table-action"
        >
            <AntDropdown
                overlay={dropdown}
                overlayClassName={styles.listActionOptions}
                disabled={noItems || loading}
            >
                <Ant.Button
                    icon={ICONS[icon]}
                    block
                    type="primary"
                    loading={loading}
                >
                    {title}
                    <span className="ml-1">{ICONS.caretDownOutlined}</span>
                </Ant.Button>
            </AntDropdown>
        </div>
    );
};
