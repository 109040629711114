import {TableConfigs, TableConfig} from '@fl/cmsch-fe-library';

import {TableId} from 'types/table-id';
import {TableRowMap} from 'types/table-row-map';

const tableConfigs: TableConfigs<TableRowMap> = {
    orders: {
        defaultParams: {
            sorting: [{field: 'createdTs', direction: 'DESC'}],
            pagination: {page: 0, size: 100},
            filters: {},
        },
        serverUserSettingsIds: {
            code: 'LIST',
            module: 'ORDERS',
        },
    },
    animals: {
        defaultParams: {
            sorting: [{field: 'earTag', direction: 'DESC'}],
            pagination: {page: 0, size: 100},
            filters: {},
        },
    },
    originInconsistencies: {
        defaultParams: {
            sorting: [{field: 'created', direction: 'DESC'}],
            pagination: {page: 0, size: 20},
            filters: {active: {type: 'boolean', value: true}},
        },
    },
    genotypeExports: {
        defaultParams: {
            sorting: [{field: 'created', direction: 'DESC'}],
            pagination: {page: 0, size: 50},
            filters: {},
        },
    },
    snpManagement: {
        defaultParams: {
            sorting: [{field: 'name', direction: 'DESC'}],
            pagination: {page: 0, size: 50},
            filters: {},
        },
    },
};

export function getTableConfig<TID extends TableId>(tableId: TID): TableConfig<TableRowMap[TID]> | undefined {
    return tableConfigs[tableId];
}
