import {none, Opt} from 'ts-opt';

import {BillingBasesExport} from 'api/gen/BillingBasesExport';
import {LastOrderUserDetails} from 'api/gen/LastOrderUserDetails';
import {OrderAnimalDetails} from 'api/gen/OrderAnimalDetails';
import {OrderCustomerDetails} from 'api/gen/OrderCustomerDetails';
import {OrderDetails} from 'api/gen/OrderDetails';
import {OrderDetailsForGenotypingRequest} from 'api/gen/OrderDetailsForGenotypingRequest';
import {OrderDetailsForProtocol} from 'api/gen/OrderDetailsForProtocol';
import {OrderSearch} from 'api/gen/OrderSearch';
import {PageOrder} from 'api/gen/PageOrder';
import {User} from 'api/gen/User';

import {ConfirmDialogData} from '../types/confirm-dialog-data';
import {ExportOrdersParams} from '../types/export-orders-params';
import {ListActionModeType} from '../types/list-action-mode-type';
import {OrderListActions} from '../types/order-list-actions';
import {OrderModals} from '../types/order-modals';

export interface OrderState {
    modalsVisibility: Array<OrderModals>;
    listActionsActive: Array<OrderListActions>;
    ordersPage: Opt<PageOrder>;
    selected: Array<number>;
    current: Opt<OrderDetails>;
    protocolData: Opt<OrderDetailsForProtocol>;
    requestData: Opt<OrderDetailsForGenotypingRequest>;
    mothersVerificationOutcome: Opt<number>;
    fathersVerificationOutcome: Opt<number>;
    parentsVerificationOutcome: Opt<number>;
    parentsVerificationError: Opt<string>;
    motherAlreadyAnalyzed: boolean;
    fatherAlreadyAnalyzed: boolean;
    customers: Opt<Array<OrderCustomerDetails>>;
    breeders: Opt<Array<User>>;
    exportBillingBaseMode: Opt<ListActionModeType>;
    generateSampleSheetMode: Opt<ListActionModeType>;
    userDetailsFromCIN: Opt<LastOrderUserDetails>;
    foundOrders: Opt<Array<OrderSearch>>;
    bulkEditOrderIds: Array<number>;
    bulkEditOrderFilter: Opt<ExportOrdersParams>;
    bulkEditOrderWithoutSelected: boolean;
    billingBaseExport: Opt<BillingBasesExport>;
    downloadWithoutForceErrors: Array<string>;
    forceDownloadConfirmed: boolean;
    foundAnimal: Opt<OrderAnimalDetails>;
    isNewOrderAnimalValid: boolean;
    newOrderEarNumberWarning: Opt<string>;
    isLoading: boolean;
    cinIsFetching: boolean;
    isAnimalDetailEditing: boolean;
    isValidating: boolean;
    confirmDialog: Opt<ConfirmDialogData>;
}

export const initialOrderState: OrderState = {
    modalsVisibility: [],
    listActionsActive: [],
    ordersPage: none,
    selected: [],
    current: none,
    protocolData: none,
    requestData: none,
    mothersVerificationOutcome: none,
    fathersVerificationOutcome: none,
    parentsVerificationOutcome: none,
    parentsVerificationError: none,
    motherAlreadyAnalyzed: false,
    fatherAlreadyAnalyzed: false,
    customers: none,
    breeders: none,
    exportBillingBaseMode: none,
    generateSampleSheetMode: none,
    userDetailsFromCIN: none,
    foundOrders: none,
    bulkEditOrderIds: [],
    bulkEditOrderFilter: none,
    bulkEditOrderWithoutSelected: false,
    billingBaseExport: none,
    downloadWithoutForceErrors: [],
    forceDownloadConfirmed: false,
    foundAnimal: none,
    isNewOrderAnimalValid: false,
    newOrderEarNumberWarning: none,
    isLoading: false,
    cinIsFetching: false,
    isAnimalDetailEditing: false,
    isValidating: false,
    confirmDialog: none,
};
