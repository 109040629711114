import {Button} from '@fl/cmsch-fe-library';
import React, {FC, Fragment, memo, PropsWithChildren, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';

import {Loader} from 'common/layout';
import {Container} from 'layout';
import {getPathRestriction} from 'layout/components/main-menu/menu-items';
import {useOurTranslation} from 'translations';

import {simpleUserSelector, userAction} from '../../model';
import {useUser} from '../../utils/useUser';

const UserPermissionGuardBase: FC<PropsWithChildren> = props => {
    const {children} = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const {t} = useOurTranslation('user');

    const {currentUser} = useSelector(simpleUserSelector.userState);
    const {isMeatAssociation, isHolsteinAssociation, loadingUser} = useUser();
    const isUserInitialized = !currentUser.isEmpty;

    const currentPathRestriction = useMemo(() =>
        getPathRestriction(currentUser, location.pathname, isMeatAssociation, isHolsteinAssociation),
    [currentUser, isHolsteinAssociation, isMeatAssociation, location.pathname]);

    const needsLogin = useMemo(() =>
        currentPathRestriction.onlyForLoggedIn || currentPathRestriction.userLacksPermissions,
    [currentPathRestriction.onlyForLoggedIn, currentPathRestriction.userLacksPermissions]);

    useEffect(() => {
        if (!isUserInitialized && !loadingUser) dispatch(userAction.getCurrentUser());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const goToLogIn = useCallback(() => {
        dispatch(userAction.logout());
    }, [dispatch]);

    const userHasAccess = useMemo(() => !currentPathRestriction.userLacksPermissions
        , [currentPathRestriction.userLacksPermissions]);

    const shouldBeLoggedIn = useMemo(() => currentUser.isEmpty && needsLogin, [currentUser.isEmpty, needsLogin]);

    if (!isUserInitialized || loadingUser) {
        return (
            <Loader
                show
                size="large"
                text={`${t('loadingUser')}`}
                noBackground
                centerPosition
            />
        );
    }

    if (shouldBeLoggedIn) {
        return (
            <Container>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h3 className="text-center">{t('notLoggedIn')}</h3>
                    <Button
                        visuals="primary"
                        onClick={goToLogIn}
                    >{t('login')}</Button>
                </div>
            </Container>
        );
    }

    if (!userHasAccess) {
        return (
            <Container>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h3 className="text-center">{t('noPermissionMessage')}</h3>
                    <Button
                        visuals="primary"
                        onClick={goToLogIn}
                    >{t('loginAsAnother')}</Button>
                </div>
            </Container>
        );
    }

    return (
        <Fragment>
            {children}
        </Fragment>
    );
};

export const UserPermissionGuard = memo(UserPermissionGuardBase);
