import {KeysOfType} from '@fl/cmsch-fe-library';

import {cinRegexGen} from 'api/gen/Cin';
import {lineRegistryMaxLength, lineRegistryRegexGen} from 'api/gen/LineRegistry';
import {tCommon} from 'translations';

import {registryPattern} from './patterns';
import {KeysOfStringType, Validator} from './validator';

export class PaternityValidator<Values> extends Validator<Values> {
    public cin(fieldName: KeysOfStringType<Values>, label: string): void {
        this.patternCustom(fieldName, cinRegexGen(), tCommon('validator.cinNotValid', {label}));
    }

    public registry(fieldName: KeysOfStringType<Values>, label: string, strict?: 'strict'): void {
        this.patternCustom(
            fieldName,
            strict ? lineRegistryRegexGen() : registryPattern,
            tCommon('validator.registryNotValid', {label}),
        );
        this.maxStringLength(fieldName, lineRegistryMaxLength, label);
    }

    public cinUserDetails(
        cinFieldName: KeysOfType<Values, string | null>,
        noCinFieldName: KeysOfType<Values, boolean | null>,
        cinLabel: string,
        noCinLabel: string,
    ): void {
        if (this.isFieldEmpty(cinFieldName) && !this.getBoolean(noCinFieldName)) {
            this.setErrorForField(cinFieldName, tCommon('validator.cinUserDetails', {cinLabel, noCinLabel}));
        }
    }

    public cinBulkEdit(
        cinFieldName: KeysOfType<Values, string | null>,
        prefillNameFieldName: KeysOfType<Values, number | null>,
        cinLabel: string,
        prefillNameLabel: string,
    ): void {
        if (this.isFieldEmpty(cinFieldName) && this.isFieldEmpty(prefillNameFieldName)) {
            this.setErrorForField(cinFieldName, tCommon('validator.cinBulkEdit', {cinLabel, prefillNameLabel}));
        }
    }
}
